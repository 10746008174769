@keyframes accordionPanelBg {
  from {
    transform: scaleX(0);
    opacity: 0;
  }

  to {
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes accordionPanelContent {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.o-accordion {
  margin: get-space(xl) 0;

  &__headline {
    text-align: center;

    @include use-grid-span(
      'width',
      (
        m: 10
      )
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide
      )
    );

    &:after {
      margin-bottom: get-space(s);
      @include mq(m) {
        margin: get-space(s) auto 0;
      }

      @include mq(l) {
        margin: get-space(s) auto 0;
      }
    }
  }

  &__wrapper {
    margin-top: get-space(l);

    @include mq(m) {
      display: flex;
    }
  }

  &__accordion {
    @include use-grid-span(
      'width',
      (
        m: 10,
        l: 5
      )
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide
      )
    );

    flex-shrink: 0;
  }

  &__desktop-panels {
    display: none;
    flex-shrink: 0;
    min-height: 558px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    @include use-grid-gutter('margin-left', (m), false);
    @include use-grid-span(
      'width',
      (
        l: 6
      )
    );

    @include mq(l) {
      display: block;
    }

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  &__item {
    width: 100%;
    display: block;
    border: 1px solid get-color(blue-dark);
    border-radius: 7px;
    color: get-color(blue-dark);
    margin-bottom: get-space(xs);
    overflow: hidden;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__control {
    width: 100%;
    padding: get-space(s);
    text-align: left;
    display: flex;
    align-items: center;

    @include mq(m) {
      padding: get-space(m) get-space(s);
    }

    span {
      @include text-type-headline-sub;
      pointer-events: none;
      flex-grow: 2;
    }

    .o-accordion__control-icon {
      pointer-events: none;
      flex-shrink: 0;
      transition: transform 0.33s ease-in-out;

      @include mq(m) {
        display: none;
      }
    }

    &:focus {
      background: get-color(grey-light, $colors-greyScale);
    }

    &[aria-expanded='true'] {
      background: get-color(petrol-light);

      @include mq(l) {
        background: get-color(petrol);
      }

      .o-accordion__control-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: get-space(s);

    svg {
      width: get-space(l);
      height: get-space(l);
      fill: get-color(blue-dark) !important;

      path,
      g {
        fill: get-color(blue-dark) !important;
      }
    }
  }

  &__panel {
    .m-mixed-copy *:last-child {
      margin-bottom: 0;
    }

    &--mobile {
      display: block;
      padding: 0 get-space(s) get-space(s);
      background: get-color(petrol-light);

      & > div {
        opacity: 0;
        animation: accordionPanelContent 400ms 200ms cubic-bezier(0.48, 0, 0.5, 1) forwards;
      }

      &[hidden] {
        display: none;
      }

      @include mq(l) {
        display: none;
      }
    }

    &--desktop {
      position: relative;
      display: none;
      height: 100%;

      & > div {
        position: relative;
        z-index: 1;
        padding: get-space(m);
        opacity: 0;
        animation: accordionPanelContent 400ms 400ms cubic-bezier(0.48, 0, 0.5, 1) forwards;
      }

      &:after {
        content: '';
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        transform-origin: left center;
        background-color: get-color(petrol-light);
        animation: accordionPanelBg 400ms cubic-bezier(0.48, 0, 0.5, 1) forwards;
      }

      &[hidden] {
        display: none;
      }

      @include mq(l) {
        display: block;
      }
    }
  }

  .m-mixed-copy {
    background: get-color(petrol-light);

    a {
      color: get-color(blue-dark);
    }
  }
}
