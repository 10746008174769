.m-category-teaser {
  position: relative;
  display: block;

  &__icon {
    transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1),
      transform 250ms cubic-bezier(0.48, 0, 0.5, 1);
  }

  &:hover {
    .m-category-teaser__icon {
      color: get-color(petrol);
      transform: translateX(5px);
    }
  }

  @include use-grid-span(
    'width',
    (
      m: 5 wider
    ),
    (4 narrow)
  );

  &__headline {
    &:after {
      background-color: get-color(petrol-light);
    }
  }

  &__copy {
    margin-bottom: get-space(s);
  }
}
