.a-input {
  $self: &;
  position: relative;
  display: block;

  &--hidden {
    display: none;
  }

  &__input {
    @include text-type-copy-focused;
    position: relative;
    width: 100%;
    border: 0;
    height: 38px;
    border-bottom: 1px solid get-color(blue-dark);
    background-color: transparent;
    color: get-color(petrol);
    letter-spacing: 0.5px;
    padding-right: get-space(s);

    &::placeholder {
      color: get-color(blue-dark);
      opacity: 1;
    }

    &:hover::placeholder,
    &:focus::placeholder {
      color: get-color(petrol);
    }

    &:focus {
      border-color: get-color(petrol);
      outline: 0;
    }

    &:disabled {
      border-color: get-color(grey, $colors-greyScale);
    }

    &:disabled::placeholder {
      color: get-color(grey, $colors-greyScale);
    }

    &::-ms-clear {
      display: none;
    }

    &[type='hidden'] {
      display: none;
    }

    &:not(:-ms-input-placeholder) + #{$self}__label {
      position: relative !important;
      height: auto;
      width: auto;
      clip: none;
    }

    &:not(:placeholder-shown) + #{$self}__label {
      position: relative !important;
      height: auto;
      width: auto;
      clip: none;
    }
  }

  &__label {
    @extend %visually-hidden;
    display: block;
    margin-top: get-space(xxs);
  }

  &__required {
    position: absolute;
    top: 0;
    right: 0px;
    font-family: $font-family-default;
    font-size: 30px;
  }

  // error
  &__error {
    @include text-type-copy;
    margin-top: get-space(xxs);
    display: none;
    color: get-color(red, $colors-tertiary);
  }

  &--error {
    color: get-color(red, $colors-tertiary);

    &:hover:not([disabled]) #{$self}__label {
      color: get-color(red, $colors-tertiary);
    }

    #{$self}__input {
      border-bottom: 1px solid get-color(red, $colors-tertiary);

      &:focus::placeholder {
        color: transparent;
      }
    }

    #{$self}__error {
      display: block;
    }

    &::placeholder,
    #{$self}__input::placeholder {
      color: get-color(red, $colors-tertiary);
    }
  }

  & .is-visible {
    display: block;
  }

  input.user-error {
    color: get-color(red, $colors-tertiary);
    border-bottom: 1px solid get-color(red, $colors-tertiary);

    &::placeholder,
    #{$self}__input::placeholder {
      color: get-color(red, $colors-tertiary);
    }
  }

  input.user-error ~ &__required {
    color: get-color(red, $colors-tertiary);
  }
}
