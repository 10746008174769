.a-video {
  position: relative;
  z-index: get-z-index('o-video');

  @media print {
    display: none;
  }

  &__consent {
    position: absolute;
    z-index: get-z-index('button', 'o-video');
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: get-color(blue-dark);
    color: get-color(blue-pastel);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: get-space(xs);
  }

  &__poster {
    position: absolute;
    z-index: get-z-index('poster', 'o-video');
    top: 0;
    left: 0;

    &-image {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__embed_container,
  &__poster,
  &__poster:after,
  &__button {
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }

  &__iframe,
  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__embed_container {
    position: relative;
    z-index: get-z-index('embed-container', 'o-video');
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    cursor: pointer;
    z-index: get-z-index('button', 'o-video');
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    background-color: transparent;

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 250ms ease-out;
      width: 90px;
      height: 90px;

      &-circle {
        transition: fill 250ms ease-out;
        fill: get-color(white, $colors-greyScale); // fallback
      }

      &-arrow {
        fill: get-color(blue-dark);
      }
    }
  }

  &--is-playing &__button,
  &--is-playing &__poster {
    display: none;
  }

  &__button:hover &__button-icon {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &__button:hover &__button-icon circle {
    fill: get-color(petrol-light);
  }

  &__caption {
    font-style: italic;
  }
}
