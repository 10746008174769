// Colors
$colors-primary: (
  blue-dark: #07233d,
  blue-night: #073357,
  blue-pastel: #f2f9ff,
  petrol: #3ba7ad,
  petrol-light: #a0d8d8
);

$colors-greyScale: (
  grey-dark: #979797,
  grey-bright: #b3b3b3,
  grey: #dfdfdf,
  grey-light: #eaeaea,
  grey-super-light: #f8f8f8,
  white: #ffffff
);

$colors-tertiary: (
  black: #000000,
  red: #d8072d,
  red-light: #ff5876,
  green: #3bad98,
  yellow: #ffe6a6
);

/**
 * Returns color from settings referenced by id
 * @param  {string} $id Identifier for the color in the configuration map
 * @param  {map} $colors Map with colors
 * @return {number} Resulting color from configuration file
 */
@function get-color($id, $colors: $colors-primary) {
  @if not(map-has-key($colors, $id)) {
    @error 'Color #{$id} not known';
  }

  @return map-get($colors, $id);
}
