.o-image-text {
  display: grid;
  grid-template-columns: auto;
  gap: get-space(s);
  overflow: hidden;

  @include mq(s) {
    grid-template-columns: 1fr 2fr;
    margin-bottom: get-space(xs);
  }

  @include mq(m) {
    margin-bottom: get-space(m);
  }

  &__image-wrapper {
    // stylelint-disable
    aspect-ratio: 1.35;
    // stylelint-enable
    position: relative;
    width: 100%;
    min-width: 7rem;
    max-width: 26rem;
    margin: -1rem auto 0 auto;

    @include mq(s) {
      margin: 0;
    }

    grid-row: 1;

    @include mq(s) {
      grid-row: 1;
      grid-column: 1 / span 1;
    }

    @include mq(m) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 1;
    }
  }

  &__image-wrapper-no-headline {
    @include mq(s) {
      grid-row: 1;
      grid-column: 1 / span 2;
    }
  }

  &__headline-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: -1.4rem;

    grid-row: 2;

    @include mq(s) {
      grid-row: 1;
      grid-column: 2 / span 1;
    }

    @include mq(m) {
      margin-bottom: -0.5rem;
    }

    @include mq(l) {
      margin-bottom: -2rem;
    }
  }

  &__copy-wrapper {
    grid-row: 3;

    @include mq(s) {
      grid-row: 2;
      grid-column: 1 / span 2;
    }

    @include mq(m) {
      grid-row: 2;
      grid-column: 2 / span 1;
    }
  }

  &__copy-wrapper-no-headline {
    @include mq(m) {
      margin-top: 3.5rem;
      grid-row: 1;
    }
  }

  &__image-wrapper .a-teaser-image__background::before {
    height: calc(100% - 40px);
    transform: translateY(53px) translateX(30px);
  }

  &__image-wrapper .a-teaser-image.is-in-viewport .a-teaser-image__background:before {
    width: 94%;
  }

  &__content {
    width: 100%;
    padding: 0 get-space(m);
  }
}

.o-image-text.o-image-text-reversed {
  grid-template-columns: auto;

  @include mq(s) {
    grid-template-columns: 2fr minmax(12rem, 1fr);
  }

  .o-image-text__image-wrapper {
    grid-row: 3;

    @include mq(s) {
      grid-row: 1;
      grid-column: 2 / span 1;
    }

    @include mq(m) {
      grid-row: 1 / span 2;
      grid-column: 2 / span 1;
    }
  }

  .o-image-text__image-wrapper-no-headline {
    @include mq(s) {
      grid-row: 1;
      grid-column: 1 / span 2;
    }
  }

  .o-image-text__headline-wrapper {
    grid-row: 1;

    @include mq(s) {
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
    }

    @include mq(m) {
      grid-column: 1 / span 1;
    }
  }

  .o-image-text__copy-wrapper {
    grid-row: 2;

    @include mq(s) {
      grid-row: 2;
      grid-column: 1 / span 2;
    }

    @include mq(m) {
      grid-row: 2;
      grid-column: 1 / span 1;
    }
  }

  .o-image-text__copy-wrapper-no-headline {
    @include mq(m) {
      margin-top: 3.5rem;
      grid-row: 1;
    }
  }

  .o-image-text__image-wrapper .a-teaser-image__background::before {
    transform: translateY(53px) translateX(-10px);
  }
}
