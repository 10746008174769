@mixin text-type-headline-main {
  font-size: get-text-property(headlineMain, fontSize);
  line-height: get-text-property(headlineMain, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(headlineMain, fontSize, m);
    line-height: get-text-property(headlineMain, lineHeight, m);
  }
}

@mixin text-type-headline-sub {
  font-size: get-text-property(headlineSub, fontSize);
  line-height: get-text-property(headlineSub, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(headlineSub, fontSize, m);
    line-height: get-text-property(headlineSub, lineHeight, m);
  }
}

@mixin text-type-headline-sub-static {
  font-size: get-text-property(headlineSub, fontSize, static);
  line-height: get-text-property(headlineSub, lineHeight, static);

  font-family: $font-family-default;
  font-weight: normal;
}

@mixin text-type-headline-sub-bold-static {
  font-size: get-text-property(headlineSub, fontSize, static);
  line-height: get-text-property(headlineSub, lineHeight, static);

  font-family: $font-family-default-bold;
  font-weight: normal;
}

@mixin text-type-headline-highlight {
  font-size: get-text-property(headlineHighlight, fontSize);
  line-height: get-text-property(headlineHighlight, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(headlineHighlight, fontSize, m);
    line-height: get-text-property(headlineHighlight, lineHeight, m);
  }
}

@mixin text-type-copy-searchlist {
  font-size: get-text-property(copySearchList, fontSize);
  line-height: get-text-property(copySearchList, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(copySearchList, fontSize, m);
    line-height: get-text-property(copySearchList, lineHeight, m);
  }
}

@mixin text-type-copy-focused {
  font-size: get-text-property(copyFocused, fontSize);
  line-height: get-text-property(copyFocused, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(copyFocused, fontSize, m);
    line-height: get-text-property(copyFocused, lineHeight, m);
  }
}

@mixin text-type-copy-focused-bold {
  font-size: get-text-property(copyFocused, fontSize);
  line-height: get-text-property(copyFocused, lineHeight);

  font-family: $font-family-default-bold;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(copyFocused, fontSize, m);
    line-height: get-text-property(copyFocused, lineHeight, m);
  }
}

@mixin text-type-copy {
  font-size: get-text-property(copy, fontSize);
  line-height: get-text-property(copy, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(copy, fontSize, m);
    line-height: get-text-property(copy, lineHeight, m);
  }
}

@mixin text-type-copy-static {
  font-size: get-text-property(copy, fontSize, m);
  line-height: get-text-property(copy, lineHeight, m);

  font-family: $font-family-default;
  font-weight: normal;
}

@mixin text-type-copy-bold {
  font-size: get-text-property(copy, fontSize);
  line-height: get-text-property(copy, lineHeight);

  font-family: $font-family-default-bold;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(copy, fontSize, m);
    line-height: get-text-property(copy, lineHeight, m);
  }
}

@mixin text-type-copy-small {
  font-size: get-text-property(copySmall, fontSize);
  line-height: get-text-property(copySmall, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(copySmall, fontSize, m);
    line-height: get-text-property(copySmall, lineHeight, m);
  }
}

@mixin text-type-numbers {
  font-size: get-text-property(numbers, fontSize);
  line-height: get-text-property(numbers, lineHeight);

  font-family: $font-family-default;
  font-weight: normal;

  @include mq(m) {
    font-size: get-text-property(numbers, fontSize, m);
    line-height: get-text-property(numbers, lineHeight, m);
  }
}

@mixin text-type-tag {
  font-size: get-text-property(tag, fontSize);
  line-height: get-text-property(tag, lineHeight);

  font-family: $font-family-default-bold;
  font-weight: normal;
  color: get-color(blue-night);
  text-transform: uppercase;

  @include mq(m) {
    font-size: get-text-property(tag, fontSize, m);
    line-height: get-text-property(tag, lineHeight, m);
  }
}
