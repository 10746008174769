// Fonts
$font-family-default: Century Gothic, Helvetica, Arial, sans-serif;
$font-family-default-bold: 'Century Gothic Bold', Helvetica, Arial, sans-serif;
$font-family-soleil: Soleil W01 SemiBold, Arial, sans-serif;

$text-types-default: (
  headlineMain: (
    lineHeight: 40px,
    fontSize: 32px,
    m: (
      lineHeight: 56px,
      fontSize: 42px
    )
  ),
  headlineHighlight: (
    lineHeight: 36px,
    fontSize: 26px,
    m: (
      lineHeight: 54px,
      fontSize: 40px
    )
  ),
  headlineSub: (
    lineHeight: 32px,
    fontSize: 20px,
    m: (
      lineHeight: 36px,
      fontSize: 26px
    ),
    static: (
      lineHeight: 36px,
      fontSize: 26px
    )
  ),
  copySearchList: (
    lineHeight: 26px,
    fontSize: 16px,
    m: (
      lineHeight: 26px,
      fontSize: 18px
    )
  ),
  copyFocused: (
    lineHeight: 28px,
    fontSize: 16px,
    m: (
      lineHeight: 34px,
      fontSize: 20px
    )
  ),
  copy: (
    lineHeight: 24px,
    fontSize: 14px,
    m: (
      lineHeight: 28px,
      fontSize: 16px
    )
  ),
  copySmall: (
    lineHeight: 24px,
    fontSize: 14px,
    m: (
      lineHeight: 24px,
      fontSize: 14px
    )
  ),
  numbers: (
    lineHeight: 1,
    fontSize: 80px,
    m: (
      lineHeight: 1,
      fontSize: 130px
    )
  ),
  tag: (
    lineHeight: 20px,
    fontSize: 14px,
    m: (
      lineHeight: 20px,
      fontSize: 14px
    )
  )
);

// Get text property from a configuration file
// @type {String} $type Text type e.g. headline
// @property {String} $property Text property from configuration file
// @text-types-default {map} $property Text properties configuration
// @text-types-default {map} $property Text properties configuration
// @return {*} Nested content
@function get-text-property($type, $property, $breakpoint: null, $text-types: $text-types-default) {
  @if $breakpoint {
    @return map-nested-get($text-types, $type, $breakpoint, $property);
  } @else {
    @return map-nested-get($text-types, $type, $property);
  }
}
