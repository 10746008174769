.o-stage-video-full {
  position: relative;
  margin: 100px 0;

  &:before {
    content: '';
    position: absolute;
    height: calc(100% + 80px);
    width: 100%;
    background-color: get-color(blue-pastel);
    top: -40px;

    @include mq(m) {
      height: calc(100% + 100px);
      top: -50px;
    }
  }

  &__video-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__content {
    margin-top: get-space(l);
  }

  &__video {
    left: -#{get-space(m)};
    width: calc(100% + #{get-space(m)});

    @include mq(m) {
      left: -$outerSpacingTablet;
      width: calc(100% + #{$outerSpacingTablet});
    }

    @include mq(l) {
      left: auto;
      width: 100%;
      margin-top: get-space(m);
    }

    .a-video__button,
    .a-video__embed-container,
    .a-video__poster {
      opacity: 0;
      transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
    }
  }

  &.is-in-viewport {
    .o-stage-video-full__video {
      .a-video__button,
      .a-video__embed-container,
      .a-video__poster {
        opacity: 1;
      }
    }
  }
}
