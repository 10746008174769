.o-faq-section {
  margin-bottom: get-space(xxl);

  .l-container__grid {
    flex-wrap: nowrap;
  }

  &__questions {
    margin-top: get-space(l);

    @include mq(m) {
      margin-top: get-space(xxl);
    }
  }

  .m-filter-navigation {
    margin-top: 0;
  }

  // React Accordion Styles
  .accordion {
    @include use-grid-span(
      'width',
      (
        m: 10 narrow,
        l: 10 narrow,
        xl: 10 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 1 wide,
        xl: 1 wide
      )
    );
  }

  .accordion__item {
    border-bottom: 1px solid get-color(grey, $colors-greyScale);
    padding: get-space(m) 0;
  }

  .accordion__button {
    [data-whatinput='mouse'] &,
    [data-whatinput='touch'] & {
      outline: 0;
    }
  }

  .accordion__icon {
    transition: all 0.3s cubic-bezier(0.48, 0, 0.5, 1);
    width: 20px;
    height: 11px;
    flex-shrink: 0;
    margin-left: get-space(xs);
  }

  .accordion__button[aria-expanded='true'] .accordion__icon {
    transform: rotateX(180deg);
  }

  .accordion__panel {
    @include use-grid-span(
      'margin-right',
      (
        m: 1 of 10 wide,
        l: 1 of 10 wide,
        xl: 1 of 10 wide
      )
    );
    padding: get-space(s) 0;

    a {
      margin-top: get-space(s);
    }
  }

  .accordion__title-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}
