.a-checkbox {
  $self: &;
  position: relative;
  cursor: pointer;

  // elements
  &__input {
    @extend %visually-hidden;
  }

  &__label {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    @include text-type-copy-focused;

    &:before {
      flex-shrink: 0;
      content: '';
      width: 32px;
      height: 32px;
      margin-right: get-space(s);
      border: 1px solid get-color(blue-dark);
      background: get-color(white, $colors-greyScale);
    }

    #{$self}__input:checked + & {
      color: get-color(petrol);

      &__required {
        color: get-color(petrol);
      }

      &:before {
        content: url('/assets/images/icons/default-check-small.svg');
        border: 1px solid get-color(petrol);
        padding: 4px 0 0 5px;

        @include mq(m) {
          padding: 1px 0 0 5px;
        }
      }
    }

    [data-whatintent='keyboard'] #{$self}__input:focus + & {
      color: get-color(petrol);

      &:before {
        @extend %emulate-focus;
      }
    }

    #{$self}__input:disabled + & {
      color: get-color(grey, $colors-greyScale);

      &:before {
        border: 1px solid get-color(grey, $colors-greyScale);
      }
    }

    // hover states
    &:hover:not([disabled]) {
      color: get-color(petrol);
      cursor: pointer;
    }

    &[disabled]:hover {
      cursor: not-allowed;
    }

    &--no-label:before {
      margin-right: 0;
    }
  }

  &__description {
    @include text-type-copy;
    display: block;
    margin-left: calc(40px + #{get-space(s)});

    a {
      color: get-color(petrol);
      text-decoration: underline;
    }

    &--no-label {
      margin-top: -32px;
    }
  }

  // required star
  &__required {
    position: relative;
    top: -8px;
    margin-left: get-space(xxs);
    font-family: $font-family-default;
    font-size: 30px;
  }

  // error
  &__error {
    @include text-type-copy;
    display: none;
    margin-top: get-space(xxs);
    margin-left: calc(40px + #{get-space(s)});
    color: get-color(red, $colors-tertiary);
  }

  &--error,
  input.user-error {
    &:hover:not([disabled]) #{$self}__label {
      color: get-color(red, $colors-tertiary);
    }

    #{$self}__error {
      display: block;
    }

    & + #{$self}__label,
    #{$self}__label {
      color: get-color(red, $colors-tertiary);
    }

    & + #{$self}__label:before,
    #{$self}__label:before {
      content: '';
      border: 1px solid get-color(red, $colors-tertiary);
    }

    #{$self}__input:checked ~ #{$self}__label {
      color: get-color(petrol);
    }

    #{$self}__input:checked ~ #{$self}__error {
      display: none;
    }
  }
}
