.a-back-to-top-button {
  position: fixed;
  z-index: 3;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  width: 60px;
  height: 60px;
  border: 0;
  border-radius: 50%;
  padding: get-space(s);
  background-color: get-color(petrol-light);
  transition: all 300ms cubic-bezier(0.48, 0, 0.5, 1);

  &:active,
  &:hover {
    background-color: get-color(grey, $colors-greyScale);
  }

  [data-whatintent='mouse'] &,
  [data-whatintent='touch'] & {
    outline: 0;
  }

  @include mq(m) {
    bottom: 50px;
    right: 50px;
  }

  @include mq(l) {
    bottom: 80px;
  }

  &__icon {
    position: relative;
    transform: rotate(180deg);
  }
}
