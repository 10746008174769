.o-search-section {
  position: relative;
  width: 100%;

  &__search {
    background: get-color(blue-dark);
    width: 100%;
    padding: 60px 0;

    .l-container__grid {
      align-items: center;
    }
  }

  &__search-wrapper {
    text-align: center;
    @include use-grid-span(
      'width',
      (
        m: 9 wider
      ),
      (4 narrow)
    );

    @include mq(m) {
      margin: 0 auto;
    }

    .a-search-auto {
      margin-bottom: 0;
    }
  }

  &__results {
    .l-container__grid {
      flex-wrap: nowrap;
    }
  }

  &__results-list {
    margin-top: get-space(l);

    @include mq(m) {
      margin-top: get-space(xxl);
    }
  }

  &__result {
    position: relative;
    display: block;
    margin-top: -1px;

    @include use-grid-span(
      'margin-left',
      (
        m: 1,
        l: 1,
        xl: 1
      )
    );

    @include use-grid-span(
      'margin-right',
      (
        m: 1,
        l: 1,
        xl: 1
      )
    );

    padding-top: get-space(m);
    @include use-grid-gutter('padding-left', (m, l, xl), false);
    @include use-grid-gutter('padding-right', (m, l, xl), false);

    &:not(:last-child):after {
      content: '';
      display: block;
      border-bottom: 1px solid get-color(grey, $colors-greyScale);
    }

    &:hover,
    &:focus {
      background-color: get-color(blue-pastel);

      &:after {
        border-color: transparent;
      }
    }
  }

  &__result-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: get-space(xs);
  }

  &__result-tags span:not(:last-child) {
    margin-right: get-space(s);
  }

  &__result-category {
    display: none;
    @include mq(m) {
      display: block;
    }
  }

  &__result-headline,
  &__result-copy,
  &__result-arrow {
    margin-bottom: get-space(s);
  }
}
