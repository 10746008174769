// We use the cookie layer implementation from hubspot
body {
  div#hs-eu-cookie-confirmation {
    @include text-type-copy-small;
    position: fixed;

    &.hs-cookie-notification-position-bottom {
      box-shadow: none;
    }

    div#hs-eu-cookie-confirmation-inner {
      max-width: $gridMaxWith;
      padding: get-space(m) 0 get-space(l) 0 !important;

      @include outer-spacing();

      @include mq(xl) {
        margin: 0 auto;
      }

      p,
      div#hs-en-cookie-confirmation-buttons-area {
        @include use-grid-span(
          'width',
          (
            m: 10 narrow,
            l: 10 narrow,
            xl: 8 narrow
          ),
          (4 narrow)
        );

        @include mq(m) {
          margin-right: auto !important;
          margin-left: auto !important;
        }
      }

      div#hs-en-cookie-confirmation-buttons-area {
        display: flex;
        justify-content: flex-end;
        margin-top: get-space(m) !important;
      }

      div#hs-eu-cookie-disclaimer {
        margin-top: get-space(xs);
      }

      a#hs-eu-confirmation-button,
      a#hs-eu-decline-button {
        padding: get-space(s) !important;
        border-radius: 5px;
        height: 60px;
        font-size: 16px;
        line-height: 22px;

        @include mq(m) {
          font-size: 20px;
        }
      }

      a#hs-eu-confirmation-button {
        order: 1;
        margin-right: 0 !important;
        color: get-color(white, $colors-greyScale) !important;
        background-color: get-color(green, $colors-tertiary) !important;
        border: 1px solid get-color(green, $colors-tertiary) !important;
      }

      a#hs-eu-decline-button {
        margin-right: get-space(s) !important;
        background-color: get-color(white, $colors-greyScale) !important;
        color: get-color(blue-dark);
        border: 1px solid get-color(grey, $colors-greyScale) !important;
      }
    }
  }
}
