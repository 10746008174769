.a-recaptcha {
  .g-recaptcha {
    display: inline-block;
  }

  &__error {
    @include text-type-copy;
    margin-top: get-space(xxs);
    display: none;
    color: get-color(red, $colors-tertiary);
  }

  .g-recaptcha.user-error + &__error {
    display: block;
  }
}
