.a-show-more-button {
  position: relative;
  display: block;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1);

  &:hover {
    color: get-color(petrol);
  }

  [data-whatinput='mouse'] &,
  [data-whatinput='touch'] & {
    outline: 0;
  }

  &__label-more,
  &__label-less {
    display: none;
  }

  [aria-expanded='false'] &__label-more {
    display: inline-block;
  }

  [aria-expanded='true'] &__label-less {
    display: inline-block;
  }

  &__icon {
    margin-right: 0;
    margin-left: 5px;
    transform: rotateX(0);
    transition: transform 250ms cubic-bezier(0.48, 0, 0.5, 1);

    [aria-expanded='true'] & {
      transform: rotateX(-180deg);
    }
  }
}
