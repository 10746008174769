.o-iframe {
  position: relative;

  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  &__headline {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 1 wide,
        xl: 1 wide
      )
    );
  }

  iframe {
    width: 100%;
    min-height: 800px;
  }
}
