ol.a-list,
ul.a-list {
  @include text-type-copy-focused;
  list-style: none;
  margin: 0 0 get-space(m);
  padding: 0;
}

ol.a-list {
  counter-reset: item;

  & > li {
    counter-increment: item;

    &:before {
      content: counter(item) '.';
      @include text-type-copy-focused-bold;

      margin-right: 10px;
    }
  }
}

ul.a-list {
  li {
    display: block;
    position: relative;
    margin-left: get-space(s);

    &:before {
      position: absolute;
      left: -20px;
      content: '\2022';
      color: get-color(petrol);
      line-height: 16px;
      top: 5px;

      @include mq(m) {
        top: 9px;
      }
    }
  }
}

ul.a-list--dash li:before {
  content: '–';
  color: get-color(black, $colors-tertiary);
}

ul.a-list--check li {
  margin-left: get-space(m);

  &:before {
    left: -30px;
    content: url('/assets/images/icons/default-check-small.svg');
    color: get-color(petrol);
  }
}

ul.a-list--plus li {
  margin-left: get-space(m);

  &:before {
    left: -30px;
    content: url('/assets/images/icons/default-plus.svg');
  }
}

ul.a-list--minus li {
  margin-left: get-space(m);

  &:before {
    left: -30px;
    content: url('/assets/images/icons/default-minus.svg');
  }
}
