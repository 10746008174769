.tickets-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  max-width: 1200px;
  overflow-y: auto;
  margin: 0 auto;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mq(m) {
    width: calc(100% - 40px);
    height: auto;
    left: 20px;
    right: 20px;
  }

  header {
    padding: get-space(m) 0 0;
  }

  main {
    padding: get-space(m) 0 get-space(xl);
    background-color: get-color(white, $colors-greyScale);
  }

  &__content-wrapper {
    width: 100%;
    height: 100%;
    background-color: get-color(white, $colors-greyScale);

    @include mq(m) {
      height: auto;
      margin: 40px 0;
    }
  }

  &__back {
    padding: get-space(l) get-space(m);
    display: flex;
    color: get-color(blue-dark);

    p {
      line-height: 1;
    }

    svg {
      width: 10px;
      height: 15px;
      margin-right: get-space(s);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(get-color(blue-dark), 0.7);
  }

  &__wrapper {
    max-width: 905px;
    width: 100%;
    margin: 0 auto;
    padding: 0 get-space(m);
  }

  &__accordion {
    &-button {
      [data-whatinput='mouse'] &,
      [data-whatinput='touch'] & {
        outline: 0;
      }
    }

    &-icon {
      transition: all 0.3s cubic-bezier(0.48, 0, 0.5, 1);
      width: 20px;
      height: 11px;
      flex-shrink: 0;
      margin-left: get-space(xs);
    }

    &-button[aria-expanded='true'] &-icon {
      transform: rotateX(180deg);
    }

    &-panel {
      background: get-color(petrol-light);
      padding: get-space(s) 0;
    }

    &-title {
      padding: get-space(s) 0;
      color: get-color(white, $colors-greyScale);
      background: get-color(petrol);

      > div {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    }
  }

  &__ticket {
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    margin-bottom: get-space(s);
    align-items: flex-end;

    @include mq(m) {
      flex-direction: row;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      z-index: 1;
      position: relative;
      padding-left: get-space(xs);
      background: get-color(petrol-light);

      @include mq(m) {
        padding: 0 get-space(xs);
      }
    }

    div {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;

      @include mq(m) {
        justify-content: flex-start;
        width: 40%;
      }

      &:before {
        bottom: 11px;
        width: 100%;
        height: 1px;
        background: get-color(blue-dark);
        content: '';
        position: absolute;
        left: 0;
        z-index: 0;
      }

      &:first-of-type {
        justify-content: flex-start;

        @include mq(m) {
          flex-grow: 1;
        }

        &:before {
          display: none;

          @include mq(m) {
            display: block;
          }
        }

        span {
          padding-left: 0;
        }
      }

      &:last-of-type {
        @include mq(m) {
          flex-grow: 2;
          width: 20%;
          justify-content: flex-end;
        }

        span {
          padding-right: 0;
        }
      }
    }
  }
}
