.o-stage-image {
  position: relative;
  display: block;

  margin: 50px 0;
  padding-bottom: 50px;

  + section {
    margin-top: get-space(l);

    @include mq(l) {
      margin-top: get-space(xl);
    }
  }

  img {
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1);
  }

  &--has-search {
    padding-bottom: 0;
  }

  &__outer {
    position: relative;
  }

  &__inner {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__background {
    position: absolute;
    height: calc(100% + 80px);
    width: 100%;
    background-color: get-color(blue-pastel);
    top: -40px;

    @include mq(m) {
      height: calc(100% + 100px);
      top: -50px;
    }
  }

  &__image-landscape {
    display: none;

    @include mq(m) {
      display: block;
      overflow: hidden;
      position: absolute;
      width: auto;
      min-height: 690px;
      height: 100%;
      right: -45%;
    }

    @include mq(l) {
      min-height: 640px;
      right: 0;
    }
  }

  &__image-wrapper {
    width: calc(100% + 30px);

    @include mq(m) {
      display: none;
    }
  }

  &__image-inner-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: percentage(1 / 1);
    margin-bottom: get-space(l);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    right: $outerSpacingMobile;
  }

  &__content {
    position: relative;

    @include mq(m) {
      min-height: 690px;
      transform: scale(0.8);
      transform-origin: top left;
    }

    @include mq(l) {
      min-height: 640px;
    }

    @media (min-width: 1280px) {
      transform: none;
    }

    h1,
    p {
      @include use-grid-span(
        'width',
        (
          m: 7 narrow,
          l: 5 narrow
        )
      );

      padding-right: $outerSpacingMobile;
    }

    p,
    a {
      margin-bottom: get-space(l);
    }

    h1 {
      margin-top: 0;
      margin-bottom: get-space(l);
      @include mq(m) {
        margin-top: get-space(xl);
        margin-bottom: get-space(s);
      }
    }
  }

  &__search {
    @include mq(m) {
      opacity: 0;
      transform: translateY(50px);
      transition: all 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
    }
  }

  &--white {
    @include mq(m) {
      color: get-color(white, $colors-greyScale);
    }
  }

  &--only-text &__text {
    @include mq(m) {
      margin-top: get-space(xxxl);
    }
  }

  &.is-in-viewport {
    img {
      opacity: 1;
    }

    .o-stage-image__search {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
