.a-button {
  display: inline-flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: get-color(blue-dark);
  padding: get-space(s);
  border-radius: 5px;
  color: get-color(white, $colors-greyScale);

  transition: color 300ms cubic-bezier(0.48, 0, 0.5, 1),
    background-position-x 300ms cubic-bezier(0.48, 0, 0.5, 1);
  background-image: linear-gradient(to right, get-color(blue-dark) 50%, get-color(petrol) 50%);
  background-size: 200% 200%;
  background-position-x: 0px;

  &:hover {
    background-position-x: 100%;
  }

  [data-whatintent='mouse'] &,
  [data-whatintent='touch'] & {
    outline: 0;
  }

  &__label {
    font-family: $font-family-default;
    font-size: 16px;
    line-height: 22px;

    @include mq(m) {
      font-size: 20px;
    }
  }

  // types
  &--primary-grey {
    background-image: linear-gradient(
      to right,
      get-color(grey, $colors-greyScale) 50%,
      get-color(petrol-light) 50%
    );
    background-color: get-color(grey, $colors-greyScale);
    color: get-color(blue-dark);
  }

  &--secondary-grey {
    background: none;
    background-color: get-color(white, $colors-greyScale);
    color: get-color(blue-dark);
    border: 1px solid get-color(grey, $colors-greyScale);
    transition: border-color 300ms cubic-bezier(0.48, 0, 0.5, 1);

    &:hover {
      border-color: get-color(petrol);
    }
  }

  // icons
  &__icon-before {
    color: get-color(white, $colors-greyScale);
  }

  &__icon-before + &__label {
    margin-left: get-space(s);
  }

  &__icon-after {
    color: get-color(white, $colors-greyScale);
  }

  &__label + &__icon-after {
    margin-left: get-space(s);
  }

  &__loading {
    svg {
      animation: rotateIcon 2s;
      animation-iteration-count: infinite;
    }
  }

  &--primary-grey &__icon-before,
  &--primary-grey &__icon-after,
  &--secondary-grey &__icon-before,
  &--secondary-grey &__icon-after {
    color: get-color(blue-dark);
  }
}
