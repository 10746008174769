$spacings-default: (
  xxs: 5px,
  xs: 12px,
  s: 20px,
  m: 30px,
  l: 40px,
  xl: 60px,
  xxl: 80px,
  xxxl: 120px
);

/**
 * Returns spacing from settings referenced by id
 * @param  {string} $id Identifier for the space in the configuration map
 * @param  {map} $spacings Map with spacings
 * @return {number} Resulting space from configuration file
 */
@function get-space($id, $spacings: $spacings-default) {
  @if not(map-has-key($spacings, $id)) {
    @error 'Spacing #{$id} not known';
  }

  @return map-get($spacings, $id);
}
