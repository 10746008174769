.o-teaser-container {
  position: relative;
  display: block;
  background-color: get-color(blue-pastel);
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .l-container__grid {
    flex-wrap: nowrap;
  }

  &__teasers {
    display: flex;
    flex-wrap: wrap;
    margin-top: get-space(xxl);

    .m-news-teaser--no-image {
      align-self: center;
    }

    @include mq($until: l) {
      justify-content: space-between;
    }
  }

  &__teaser {
    margin-bottom: get-space(xl);

    @include mq(l) {
      margin-bottom: get-space(xxl);
      @include use-grid-gutter('margin-left', (), true);
    }

    &:not(:nth-child(3n + 1)) {
      @include use-grid-span(
        'margin-left',
        (
          l: 1 narrow
        )
      );
    }
  }
}
