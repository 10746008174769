// Cedntury Gothic
@font-face {
  font-family: 'Century Gothic';
  font-display: swap;
  src: url('/assets/fonts/centurygothic/396A2E_0_0.woff2') format('woff2'),
    url('/assets/fonts/centurygothic/396A2E_0_0.woff') format('woff');
}

// Cedntury Gothic Bold
@font-face {
  font-family: 'Century Gothic Bold';
  font-weight: bold;
  font-display: swap;
  src: url('/assets/fonts/centurygothicbold/39997F_0_0.woff2') format('woff2'),
    url('/assets/fonts/centurygothicbold/39997F_0_0.woff') format('woff');
}
