.m-tickets {
  margin-bottom: get-space(m);
  width: 100%;

  &--has-layout {
    margin-top: get-space(l);
    margin-bottom: get-space(xxl);

    @include use-grid-span(
      'width',
      (
        m: 12 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );
  }

  &__ticket {
    margin: get-space(s) 0;
    padding: get-space(m);
    background: get-color(petrol-light);
    border-radius: 7px;
    display: flex;
    flex-direction: column;

    @include mq(m) {
      flex-direction: row;
    }

    h3 + * {
      margin-top: get-space(xxs);
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & > div:first-of-type {
      width: 100%;
      border-bottom: 1px solid get-color(blue-dark);
      padding: 0 0 get-space(s);

      @include mq(m) {
        width: 200px;
        padding: get-space(xs) get-space(m);
        padding-left: 0;
        border-bottom: 0;
        border-right: 1px solid get-color(blue-dark);
      }
    }

    & > div:last-of-type {
      width: 100%;
      padding: get-space(s) 0 0;

      @include mq(m) {
        width: calc(100% - 200px);
        padding: get-space(xs) get-space(m);
        padding-left: get-space(xl);
      }
    }
  }

  &__save {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: get-color(blue-dark);
    padding-top: get-space(s);

    @include mq(m) {
      padding-top: 0;
    }

    svg {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      margin-right: get-space(xs);
    }
  }
}
