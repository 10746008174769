.a-faq-list-link {
  $self: &;

  &:first-child {
    #{$self}__anchor {
      border-top: 1px solid get-color(grey-bright, $colors-greyScale);
    }
  }

  &__anchor,
  &__label,
  &__icon {
    transition: all 250ms cubic-bezier(0.48, 0, 0.5, 1);
  }

  &__anchor {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid get-color(grey-bright, $colors-greyScale);
    padding: get-space(s) 0;
    color: get-color(grey-bright, $colors-greyScale);
    text-decoration: none;

    @include mq(m) {
      align-items: flex-start;
      padding: get-space(s) get-space(xs);
    }

    &:hover #{$self}__label {
      color: get-color(white, $colors-greyScale);
    }

    &:hover {
      svg {
        transform: translateX(5px);
        fill: get-color(white, $colors-greyScale);
      }
    }
  }

  &__label {
    flex-basis: calc(100% - 31px);
    padding-right: 12px;
    @include text-type-copy-searchlist;
  }

  &__icon {
    flex-basis: 31px;
  }

  // FAQ Links look different in a FAQ Search Box
  .m-faq-box--search & {
    &:not(:first-child) {
      #{$self}__anchor {
        margin-top: -1px;
      }
    }

    #{$self}__anchor {
      border: 1px solid get-color(grey-bright, $colors-greyScale);
      padding-right: get-space(xs);
      padding-left: get-space(xs);

      &:hover {
        border-color: get-color(white, $colors-greyScale);
        z-index: 1;
      }
    }
  }
}
