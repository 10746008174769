.a-teaser-image {
  margin: get-space(s) 0 get-space(m);

  @include mq(m) {
    margin-bottom: get-space(l);
  }

  &__background {
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 15px);

    &:before {
      content: ' ';
      position: absolute;
      opacity: 0;
      background-color: get-color(grey-light, $colors-greyScale);
      transition: width 400ms cubic-bezier(0.48, 0, 0.5, 1);
      width: 0;
      height: 100%;
    }

    .a-teaser-image--bg-pos-1 & {
      height: calc(100% + 25px);
      left: -10px;
      top: -15px;
    }

    .a-teaser-image--bg-pos-2 & {
      left: -15px;
      top: -20px;
    }

    .a-teaser-image--bg-pos-3 & {
      left: -20px;
      top: -15px;
    }

    .a-teaser-image--bg-pos-4 & {
      left: -20px;
      top: 0;
    }

    .a-teaser-image--bg-pos-5 & {
      left: -10px;
      top: 0;
    }

    .a-teaser-image--bg-pos-content & {
      width: 100%;
      height: 100%;
      left: $outerSpacingMobile;
      top: 40px;

      @include mq(m) {
        width: calc(100% - #{$outerSpacingTablet});
        height: calc(100% - #{$outerSpacingTablet});
        top: $outerSpacingTablet * 1.5;
        left: auto;
      }

      @include mq(l) {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        top: 80px;
      }

      @include respond-to-susy(m) {
        right: gutter() * -1;
      }

      @include respond-to-susy(l) {
        right: gutter() * -1;
      }

      @include respond-to-susy(xl) {
        right: gutter() * -1;
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
  }

  &__image {
    position: relative;
    width: 100%;
    height: 100%;
    // stylelint-disable
    aspect-ratio: 1;
    // stylelint-enable
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
  }

  &--bg-pos-content {
    margin-top: 0;
    margin-left: $outerSpacingMobile * -1;
    @include mq(m) {
      width: calc(100% + #{$outerSpacingTablet});
      height: calc(100% + #{$outerSpacingTablet});
      margin-top: 0;
      margin-left: $outerSpacingTablet * -1;
    }

    @include mq(xl) {
      width: calc(100% + #{$outerSpacingDesktop});
      height: calc(100% + #{$outerSpacingDesktop});
      margin-top: 0;
      margin-bottom: 30px;
      margin-left: $outerSpacingDesktop * -1;
    }
  }

  &.is-in-viewport {
    .a-teaser-image__image {
      opacity: 1;
    }

    .a-teaser-image__background:before {
      opacity: 1;
      width: 100%;
    }
  }
}
