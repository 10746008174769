.ticket-item {
  display: flex;
  align-items: stretch;
  padding: get-space(s);
  margin: 20px 0;
  position: relative;
  border-radius: 7px;
  background-color: get-color(grey-super-light, $colors-greyScale);
  flex-direction: column;

  @include mq(m) {
    padding: get-space(l);
    flex-direction: row;
  }

  &.is-selected {
    background: get-color('petrol-light');
  }

  &.is-disabled {
    opacity: 0.5;
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    padding-bottom: get-space(s);
    justify-content: center;
    border-bottom: 1px solid get-color(blue-dark);

    @include mq(m) {
      padding-right: get-space(l);
      min-width: 210px;
      flex-shrink: 0;
      padding-bottom: 0;
      border-bottom: 0;
      border-right: 1px solid get-color(blue-dark);
    }
  }

  & > div:last-of-type {
    padding: get-space(s) 0;
    width: 100%;

    @include mq(m) {
      padding-left: get-space(xl);
    }
  }

  &__checkbox {
    position: absolute;
    top: get-space(s);
    right: get-space(s);

    @include mq(m) {
      top: get-space(xl);
      right: get-space(l);
    }
  }

  &__description {
    text-align: right;

    @include mq(m) {
      position: absolute;
      bottom: get-space(l);
      right: get-space(l);
    }
  }
}
