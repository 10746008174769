.a-loading-button {
  display: inline-flex;
  position: relative;
  border: 0;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: get-color(grey, $colors-greyScale);
  color: get-color(blue-dark);
  padding: get-space(s);
  border-radius: 5px;
  cursor: pointer;

  [data-whatintent='mouse'] &,
  [data-whatintent='touch'] & {
    outline: 0;
  }

  &__label {
    font-family: $font-family-default;
    font-size: 16px;
    line-height: 22px;

    @include mq(m) {
      font-size: 20px;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      animation: rotateIcon 2s;
      animation-iteration-count: infinite;
    }

    &--hidden {
      display: none;
    }
  }

  /* Animation Keyframes*/
  @keyframes rotateIcon {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
