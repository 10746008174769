.o-teaser-container-categories {
  position: relative;
  display: block;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .l-container__grid {
    flex-wrap: nowrap;
  }

  &__headline {
    margin-top: 0;
    margin-bottom: get-space(xl);

    @include mq(m) {
      margin-bottom: get-space(xxl);
    }

    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 1 wide,
        xl: 1 wide
      )
    );
  }

  &__filter {
    margin-top: 0;
    margin-bottom: get-space(xxl);
    margin-left: get-space(s) !important;
  }

  &__teasers {
    display: flex;
    flex-wrap: wrap;

    @include mq($until: l) {
      justify-content: space-between;
    }
  }

  &__teaser {
    &:not(:nth-child(2n + 1)) {
      @include use-grid-span(
        'margin-left',
        (
          l: 1 narrow
        )
      );
    }

    & + & {
      margin-top: get-space(xl);

      @include mq(m) {
        margin-top: 0;
      }

      &:not(:nth-child(2)) {
        @include mq(m) {
          margin-top: get-space(xl);
        }

        @include mq(l) {
          margin-top: get-space(xxl);
        }
      }
    }

    @include mq(l) {
      @include use-grid-gutter('margin-left', (), true);
    }
  }
}
