.o-content-filter {
  position: relative;

  .m-mixed-copy &__intro {
    @include text-type-headline-sub-static;
  }

  .m-mixed-copy &__filters {
    margin-bottom: 0 !important;

    button {
      cursor: pointer;
      background: transparent;
      border: 0;
      padding: 0;
      margin: get-space(xs) 0;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      margin-right: get-space(s);
      position: relative;

      &[data-has-icon='true'] {
        margin-left: calc(30px + #{get-space(xs)});

        @include mq(m) {
          margin-left: calc(60px + #{get-space(s)});
        }
      }

      &[disabled] {
        cursor: not-allowed;
      }

      svg {
        max-width: 30px;
        width: 100%;
        position: absolute;
        top: 0;
        left: calc(-30px + (#{get-space(xs)} * -1));
        fill: currentColor !important;

        path {
          fill: currentColor !important;
        }

        @include mq(m) {
          left: calc(-60px + (#{get-space(s)} * -1));
          max-width: 60px;
        }
      }
    }

    button[aria-pressed='true'] {
      color: get-color(yellow, $colors-tertiary);
    }
  }

  &__content-elements {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      transform: translateX(-100%);
      background-color: get-color(white, $colors-greyScale);
      transition: all 400ms cubic-bezier(0.48, 0, 0.5, 1);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: 30% 100%;
      background-position: -50% 0%;
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(223, 223, 223, 0.3) 40%,
        rgba(223, 223, 223, 0.3) 100%
      );
    }
  }

  &--is-loading + .o-content-filter__content-elements {
    &:before {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }

    &:after {
      animation: loading 2s cubic-bezier(0.48, 0, 0.5, 1) infinite 400ms;
      z-index: 10;
    }
  }
}

@keyframes loading {
  0% {
    background-position: -50% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}
