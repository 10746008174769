.o-divider {
  background: get-color(blue-dark);
  margin: get-space(xl) 0;
  width: 100%;
  padding: 60px 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .l-container__grid {
    align-items: center;
  }

  &__wrapper {
    text-align: center;
    @include use-grid-span(
      'width',
      (
        m: 8 narrow
      )
    );

    @include mq(m) {
      margin: 0 auto;
    }
  }

  .m-mixed-copy {
    h1,
    h2,
    h3,
    span {
      display: block;
      @include text-type-headline-highlight;
      margin-bottom: get-space(l);
    }
  }
}
