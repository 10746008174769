.m-quote {
  width: 100%;
  padding: 50px 0 get-space(xl);
  background-color: get-color(blue-pastel);

  @include mq(m) {
    padding: get-space(xxl) 0;
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;

    @include mq(l) {
      flex-flow: row nowrap;
      align-items: center;
    }
  }

  &__headline {
    position: relative;
    @include use-grid-span(
      'width',
      (
        m: 10 narrow,
        l: 5 narrow
      ),
      (4 narrow)
    );

    @include mq(m) {
      margin: 0 auto get-space(l) auto;
      text-align: center;
    }

    &:after {
      margin-bottom: get-space(s);
      @include mq(m) {
        margin: get-space(s) auto 0;
      }

      @include mq(l) {
        margin: get-space(s) auto 0;
      }
    }
  }

  &__image {
    @include use-grid-span(
      'width',
      (
        m: 5 narrow,
        l: 4 narrow
      ),
      (3 narrow)
    );
    @include use-grid-span(
      'margin-left',
      (
        l: 1 wide
      ),
      false
    );
    margin-bottom: get-space(l);
    border-radius: 100%;
  }

  &__text {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    @include use-grid-span(
      'width',
      (
        l: 5 wider
      ),
      (4 narrow)
    );
    @include use-grid-gutter('margin-left', (l), false);
    @include use-grid-gutter('padding-left', (l), false);
  }

  &__icon {
    margin-bottom: get-space(s);
    color: get-color(grey, $colors-greyScale);

    @include mq(l) {
      margin-bottom: get-space(m);
    }
  }

  &__tag {
    max-width: 100%;
    @include text-type-copy-bold;
    color: get-color(blue-night);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: get-space(s);

    @include mq(l) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: get-space(xs);
    }
  }

  &__quote {
    max-width: 100%;
    margin-bottom: get-space(m);
  }

  &__copy {
    max-width: 100%;
    margin-bottom: get-space(l);

    @include mq(m) {
      margin-bottom: 50px;
    }
  }

  &--image-right {
    .m-quote__text {
      flex-grow: 2;
      @include use-grid-span(
        'margin-left',
        (
          l: 1 wide
        ),
        false
      );
      padding-left: 0;
      @include use-grid-span(
        'width',
        (
          l: 4 narrow
        ),
        (4 narrow)
      );
    }

    .m-quote__image {
      @include use-grid-span(
        'margin-right',
        (
          l: 1 wide
        ),
        false
      );
      @include use-grid-gutter('margin-left', (l), false);
      @include use-grid-gutter('padding-left', (l), false);
    }

    .m-quote__wrapper {
      @include mq(l) {
        flex-flow: row-reverse nowrap;
      }
    }
  }
}
