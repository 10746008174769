.o-event-ticket-list {
  background-color: get-color(petrol-light, $colors-primary);

  &__wrapper {
    width: 100%;
  }

  &__wrapper--centered {
    text-align: center;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: get-space(s);

    @include mq(m) {
      flex-direction: row;
    }

    svg {
      width: 22px;
      height: 22px;
      margin-bottom: get-space(xs);

      @include mq(m) {
        margin-bottom: 0;
        margin-right: get-space(xs);
      }
    }
  }

  &__register {
    position: absolute;
    bottom: get-space(xl);
    width: 100%;

    @include mq(m) {
      margin: 0 get-space(m);
      width: calc(100% - (2 * #{get-space(m)}));
    }
  }

  &__accordion {
    width: 100%;
    padding-bottom: 60px;

    &-inner {
      background-color: get-color(white, $colors-greyScale);
      padding: get-space(xs) get-space(m);
      margin-bottom: get-space(m);
      margin-left: -30px;
      margin-right: -30px;

      @include mq(m) {
        padding: get-space(l) get-space(xl);
      }
    }
  }

  &__single-ticket {
    background-color: get-color(white, $colors-greyScale);
    padding: get-space(xl) get-space(l);

    form {
      padding-top: 0;
    }
  }

  &__text {
    padding: get-space(xl) 0;
  }

  &__action-button {
    width: 100%;
  }
}
