.o-stage-video {
  position: relative;
  display: block;

  margin: 50px 0;
  padding-bottom: 50px;

  + section {
    margin-top: get-space(l);

    @include mq(l) {
      margin-top: get-space(xl);
    }
  }

  &__outer {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: calc(100% + 80px);
      width: 100%;
      background-color: get-color(blue-pastel);
      top: -40px;

      @include mq(m) {
        height: calc(100% + 100px);
        top: -50px;
      }
    }
  }

  &__inner {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__content {
    position: relative;
    @include use-grid-span(
      'width',
      (
        l: 10 narrow,
        xl: 10 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        l: 1 wide,
        xl: 1 wide
      )
    );

    h1,
    p {
      position: relative;
      padding-right: $outerSpacingMobile;

      @include mq(l) {
        padding-left: $outerSpacingMobile;
      }
    }

    p,
    a {
      margin-top: get-space(l);
    }

    h1 {
      order: -1;
      margin-top: 0;
      margin-bottom: get-space(l);

      @include mq(l) {
        position: absolute;
        bottom: 0;
        margin-bottom: get-space(m);
        color: get-color(white, $colors-greyScale);
        opacity: 0;
        transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
      }
    }
  }

  &__video-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__video {
    left: -#{get-space(m)};
    width: calc(100% + #{get-space(m)});

    @include mq(m) {
      left: -$outerSpacingTablet;
      width: calc(100% + #{$outerSpacingTablet});
    }

    @include mq(l) {
      left: auto;
      width: 100%;
      margin-top: get-space(m);

      &.a-video--is-playing + h1 {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: get-space(l);
      width: 0;
      height: calc(100% + #{get-space(l)});
      background-color: get-color(petrol-light);
      transition: width 400ms cubic-bezier(0.48, 0, 0.5, 1);

      @include use-grid-span(
        'margin-left',
        (
          l: 1 wide of 10,
          xl: 1 wide of 10
        )
      );

      @include mq(l) {
        top: -#{get-space(m)};
        bottom: auto;
        height: 100%;
      }
    }

    .a-video__button,
    .a-video__embed-container,
    .a-video__poster {
      opacity: 0;
      transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
    }
  }

  &.is-in-viewport {
    .o-stage-video__video {
      &:before {
        width: 100vw;

        @include mq(l) {
          width: calc(100% - #{get-space(l)});
        }
      }

      .a-video__button,
      .a-video__embed-container,
      .a-video__poster,
      & + h1 {
        opacity: 1;
      }
    }
  }
}
