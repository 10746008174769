.m-news-teaser {
  position: relative;
  display: block;

  @include mq(m) {
    padding: 0 get-space(s);
  }

  @include use-grid-span(
    'width',
    (
      m: 5 narrow,
      l: 3 wider
    ),
    (4 narrow)
  );

  &--no-image {
    @include use-grid-span(
      'width',
      (
        m: 5 wider,
        l: 3 wide
      ),
      (4 narrow)
    );

    @include mq(m) {
      padding: 0;
    }
  }

  &--medium,
  &--no-image.m-news-teaser--medium {
    @include use-grid-span(
      'width',
      (
        m: 5 wider,
        l: 5 wider
      ),
      (4 narrow)
    );
  }

  &__headline,
  &__copy,
  &__icon {
    margin-top: get-space(s);
  }

  &__icon {
    transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1),
      transform 250ms cubic-bezier(0.48, 0, 0.5, 1);
  }

  &:hover {
    .m-news-teaser__icon {
      color: get-color(petrol);
      transform: translateX(5px);
    }
  }
}
