.m-filter-navigation {
  position: relative;
  display: block;
  margin-top: get-space(xxl);

  @include use-grid-span(
    'margin-left',
    (
      m: 1 wide
    )
  );

  &__headline {
    margin: 0;
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );
  }

  &__filter-toggle {
    padding: 0;
    margin-top: 5px;
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  &__toggle-icon {
    margin-right: 0;
    margin-left: 5px;
    transform: rotateX(-180deg);
    transition: transform 250ms cubic-bezier(0.48, 0, 0.5, 1);

    @include mq(m) {
      transform: rotateX(0);
    }

    .toggled & {
      transform: rotateX(0);

      @include mq(m) {
        transform: rotateX(-180deg);
      }
    }
  }

  &__filters {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
  }

  //css only solution to hide filters on mobile
  &.has-filter-toggle {
    .m-filter-navigation__filters {
      @include mq(m) {
        display: none;
      }
    }

    .toggled + .m-filter-navigation__filters {
      display: none;

      @include mq(m) {
        display: flex;
        margin-top: 0;
      }
    }
  }

  &__filter-tag {
    position: relative;
    font-family: $font-family-default-bold;
    font-size: 16px;
    line-height: 28px;
    color: get-color(blue-dark);
    margin: get-space(s) get-space(m) 0 0;
    cursor: pointer;

    &--multiple {
      @include text-type-copy-searchlist;
      word-break: break-word;
      hyphens: auto;
      margin: get-space(s) 0 0 0;
      display: flex;
      align-items: center;
      border: 1px solid get-color(grey, $colors-greyScale);
      border-radius: 4px;
      width: 100%;

      @include mq(s) {
        width: auto;
        margin: get-space(s) get-space(m) 0 0;
      }

      @include mq(m) {
        margin-top: get-space(m);
      }

      &:before {
        content: '';
        background: get-color(grey, $colors-greyScale);
        width: 34px;
        height: 100%;
        flex-shrink: 0;
      }

      svg {
        position: absolute;
        display: none;
        width: 21px;
        height: 15px;
        left: 7px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--active:not(&--multiple) {
      color: get-color(petrol);
      border-bottom: 3px solid get-color(petrol);
    }

    &--active.m-filter-navigation__filter-tag--multiple {
      &:before {
        background-color: get-color(petrol);
      }

      svg {
        display: block;
      }
    }
  }

  &__filter-tag,
  &__filter-toggle {
    transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1);

    &:hover {
      color: get-color(petrol);
    }

    [data-whatinput='mouse'] &,
    [data-whatinput='touch'] & {
      outline: 0;
    }
  }

  &__filter-label {
    padding: 4px get-space(s);
  }

  &__toggle {
    display: flex;
  }
}
