.o-before-after-slider {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: get-space(l) 0;

  &__image {
    width: 100%;
    height: 100%;
  }

  &__handle {
    background: get-color(blue-pastel);
    width: 3px;
    height: 100%;
    cursor: pointer;
  }

  & > div {
    width: 100%;
  }
}
