.o-form {
  position: relative;
  overflow: hidden;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .m-quote + & {
    background-color: get-color(blue-pastel);
    margin: 0;
    padding-bottom: get-space(xl);

    @include mq(l) {
      padding-bottom: get-space(xxl);
    }
  }

  // states
  .is-visible {
    display: block;
  }

  // elements
  &__row,
  &__submit-wrapper {
    margin-right: auto;
    margin-left: auto;
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );
  }

  &__row {
    @include mq(l) {
      margin: get-space(l) auto 0 auto;

      &:first-child {
        margin: 0 auto;
      }
    }

    &-wrapper {
      display: flex;
      flex-flow: row wrap;

      @include mq(l) {
        flex-flow: row nowrap;
      }
    }
  }

  &__item {
    @include use-grid-span(
      'width',
      (
        l: 6 narrow
      ),
      (4 narrow)
    );

    @include mq($until: l) {
      margin-top: get-space(m);
    }

    &:not(:last-child) {
      @include mq(l) {
        @include use-grid-gutter('margin-right', (l, xl), false);
      }
    }

    // modifier
    &--fullwidth {
      @include use-grid-span(
        'width',
        (
          m: 12 narrow,
          l: 12 narrow,
          xl: 12 narrow
        ),
        (4 narrow)
      );
    }

    &--align-right {
      text-align: right;
    }

    &--hidden {
      display: none;
    }
  }

  // submit button
  &__submit-wrapper {
    position: relative;
    width: 100%;
    text-align: right;

    @include mq($until: l) {
      margin-top: get-space(m);
    }
  }

  &__submit {
    display: inline-flex;
    cursor: pointer;
  }

  // error
  &__error {
    @include text-type-copy;
    margin-top: get-space(xxs);
    display: none;
    color: get-color(red, $colors-tertiary);
  }

  &__recaptcha.user-error + &__error {
    display: block;
  }
}
