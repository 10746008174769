.a-copy {
  @include text-type-copy;

  &--static {
    @include text-type-copy-static;
  }

  &--focused {
    @include text-type-copy-focused;
  }

  &--focused-bold {
    @include text-type-copy-focused-bold;
  }

  &--searchlist {
    @include text-type-copy-searchlist;
  }

  &--small {
    @include text-type-copy-small;
  }

  &--tag {
    @include text-type-tag;
  }
}
