// TODO: Clean up for icons, that appear in multiple modules with different sizes
.a-svg-sprite {
  width: 100px;
  height: 100px;

  svg {
    fill: currentColor;
  }

  &--default-arrow {
    width: 31px;
    height: 22px;
  }

  &--default-arrow-small {
    width: 9px;
    height: 15px;
  }

  &--default-arrow-down {
    width: 20px;
    height: 11px;
  }

  &--default-arrow-small-down {
    width: 15px;
    height: 9px;
  }

  &--default-arrow-upload {
    width: 16px;
    height: 22px;
  }

  &--default-arrow-spinner {
    width: 22px;
    height: 22px;
  }

  // &--default-check {
  //   width: 29px;
  //   height: 20px;

  //   @include mq(s) {
  //     width: 68px;
  //     height: 47px;
  //   }

  //   &-list {
  //     width: 21px;
  //     height: 15px;
  //   }
  // }
  &--default-circle-check {
    width: 60px;
    height: 60px;

    @include mq(s) {
      width: 138px;
      height: 138px;
    }
  }

  &--default-confirm {
    width: 140px;
    height: 140px;
  }

  &--default-edit {
    width: 28px;
    height: 28px;
  }

  &--default-write {
    width: 36px;
    height: 28px;
  }

  &--default-quote {
    width: 46px;
    height: 46px;
  }

  &--default-search {
    width: 30px;
    height: 31px;

    &-small {
      width: 21px;
      height: 22px;
    }
  }

  &--search-page,
  &--search-question {
    width: 21px;
    height: 22px;
  }

  &--default-close {
    width: 22px;
    height: 22px;
  }

  &--default-burger {
    width: 29px;
    height: 19px;
  }

  &--social-media {
    &-facebook {
      width: 48px;
      height: 49px;

      .bg {
        color: get-color(petrol);
      }
    }

    &-linkedin {
      width: 53px;
      height: 49px;
    }

    &-kununu {
      width: 50px;
      height: 51px;
    }

    &-xing {
      width: 52px;
      height: 49px;
    }

    &-instagram {
      width: 56px;
      height: 49px;
    }

    &-youtube {
      width: 56px;
      height: 49px;
    }
  }

  &--breadcrumb-arrow {
    width: 5px;
    height: 7px;
  }

  &--blue-dark {
    color: get-color(blue-dark);
  }

  &--grey-dark {
    color: get-color(grey-dark, $colors-greyScale);
  }

  &--grey {
    color: get-color(grey, $colors-greyScale);
  }

  &--grey-light {
    color: get-color(grey-light, $colors-greyScale);
  }

  &--petrol {
    color: get-color(petrol);
  }

  &--white {
    color: get-color(white, $colors-greyScale);
  }
}

svg [class$='bg'],
symbol [class$='bg'] {
  fill: get-color(petrol);
}

svg [class$='letter'],
symbol [class$='letter'] {
  fill: get-color(white, $colors-greyScale);
}
