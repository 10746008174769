.m-download {
  $self: &;
  position: relative;
  width: 100%;
  margin: get-space(xl) 0;

  @include mq(m) {
    margin: get-space(xxl) 0;
  }

  &__image {
    position: relative;
    margin-top: 15px;
    @include use-grid-span(
      'width',
      (
        m: 3 narrow,
        l: 2 narrow,
        xl: 2 narrow
      ),
      (2 narrow)
    );
  }

  &__image-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 20px);
      height: calc(100% + 15px);
      top: -15px;
      left: -10px;
      background: get-color(grey-light, $colors-greyScale);
    }
  }

  &__preview-image {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__preview-icon {
    position: absolute;
    height: 100%;
    width: 100%;
    background: get-color(blue-pastel);

    svg {
      position: absolute;
      padding: get-space(m) 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    margin-top: get-space(m);
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include mq(m) {
      margin-top: 0;
      margin-left: get-space(l);
    }
  }

  &__headline,
  &__copy {
    margin-top: get-space(xs);
  }

  &__button {
    margin-top: get-space(m);
    cursor: pointer;

    svg {
      flex-shrink: 0;
      transform: rotate(180deg);
    }
  }

  &__form-wrapper {
    background-color: get-color(blue-pastel);
    margin-top: get-space('l');
    padding: get-space('s') 0 get-space('l') 0;
    display: none;

    #{$self}--form-is-open & {
      display: block;
    }

    form {
      margin-top: get-space('l');
      margin-bottom: 0;
    }
  }

  &__button-close {
    cursor: pointer;
    color: get-color(petrol);
    background: none;
    border: 0;
    padding: 0;
    align-self: flex-end;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__form-headline {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );
  }

  .o-content-copy {
    margin-top: get-space(l);
  }
}
