$gridConfigs: (
  m: (
    'columns': susy-repeat(12),
    'gutters': 15px,
    'svg-grid-colors': hsla(100, 100%, 50%, 0.2)
  ),
  l: (
    'columns': susy-repeat(12),
    'gutters': 20px,
    'svg-grid-colors': hsla(100, 100%, 50%, 0.2)
  ),
  xl: (
    'columns': susy-repeat(12),
    'gutters': 30px,
    'svg-grid-colors': hsla(100, 100%, 50%, 0.2)
  )
);
$gridMaxWith: 1280px;

$outerSpacingDesktop: 80px;
$outerSpacingTablet: 54px;
$outerSpacingMobile: 30px;

$susy: (
  'columns': susy-repeat(4),
  'gutters': 20px,
  'svg-grid-colors': hsla(100, 100%, 50%, 0.2)
);

/**
 * Returns color from settings referenced by id
 * @param  {string} $breakpoint Identifier for the color in the configuration map
 * @param  {map} $colors Map with colors
 * @return {number} Resulting color from configuration file
 */
@function get-susy-config($breakpoint) {
  $config: map-get($gridConfigs, $breakpoint);

  @if $config == null {
    @return susy-compile($susy);
  }

  @return susy-compile($config);
}

@mixin respond-to-susy($breakpoint, $inherit: true) {
  @include mq($breakpoint) {
    // save default susy configuration
    $defaultSusy: $susy;

    // get breakpoint specific susy configuration
    $tempSusyConfig: get-susy-config($breakpoint);

    @if ($inherit) {
      // merge susy configurations
      $susy: map-merge($susy, $tempSusyConfig) !global;
    }

    @content;

    // reset susy to default configuration
    $susy: $defaultSusy !global;
  }
}

@mixin grid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

/**
 * Get responsive gutter calculation
 * @param {String} css property
 * @param {Map} map containing column configuration for breakpoints (mobile: 4 of 6, desktop: 10 of 12)
 */
@mixin use-grid-span($property, $columns: (), $base: null) {
  @if ($base) {
    #{$property}: span($base);
  }

  @each $breakpoint in map-keys($columns) {
    @include respond-to-susy($breakpoint) {
      #{$property}: span(map-get($columns, $breakpoint));
    }
  }
}

/**
 * Sets the responsive gutter calculation to a specific attribute
 * @param {String} $property css property that should get the value
 * @param {List} $breakpoints List of breakpoints the property should get the gutter for
 * @param {Boolean} $base ist of breakpoints to exclude
 */
@mixin use-grid-gutter($property, $breakpoints: (), $base: false) {
  @if ($base) {
    #{$property}: gutter();
  }

  @each $breakpoint in $breakpoints {
    @include respond-to-susy($breakpoint) {
      #{$property}: gutter();
    }
  }
}
