.m-faq-box {
  position: relative;
  background-color: get-color(blue-dark);
  box-shadow: 5px 5px 25px -5px rgba(7, 51, 87, 0.75);

  @include mq(m) {
    padding-top: get-space(xl);
    padding-bottom: get-space(m);
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--no-search {
    padding: get-space(l) get-space(m) get-space(m);
    @include use-grid-span(
      'width',
      (
        l: 4 wide
      ),
      (4 wider)
    );
    @include use-grid-gutter('margin-left', (l, xl), false);
    @include use-grid-gutter('padding-left', (l, xl), false);
    @include use-grid-gutter('padding-right', (l, xl), false);

    .a-faq-list-link__anchor {
      color: get-color(grey-bright, $colors-greyScale);
      border-color: get-color(grey-bright, $colors-greyScale);
    }
  }

  &--search {
    padding: get-space(l) 0 get-space(m) 0;
    @include use-grid-span(
      'width',
      (
        l: 10 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        l: 1 wide
      )
    );

    @include mq(m) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include mq(l) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__links {
    list-style: none;
    margin: 13px 0 30px;
    padding: 0;

    @include mq(l) {
      margin: 20px 0 40px;
    }
  }

  &__readmore {
    &:hover {
      color: get-color(petrol);
    }
  }
}
