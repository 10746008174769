.o-modal {
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: get-z-index('o-modal');

  @include mq(m) {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__wrapper {
    display: flex;
    max-width: 100%;
    min-height: 100%;

    /* workaround for flexbugs #3 (IE does not align-items with min-height) */
    &::before {
      @include mq(m) {
        content: '';
        display: block;
        height: calc(100vh - #{2 * get-space(l)});
      }
    }
  }

  &__content {
    background: get-color(blue-pastel);
    flex: 1 1 0%;
    position: relative;
  }

  &__close {
    padding: get-space(xs);
    position: absolute;
    right: get-space(m);
    top: get-space(m);

    .a-svg-sprite {
      display: block;
      height: 20px;
      width: 20px;
    }
  }
}
