.tickets-form {
  padding: get-space(xl) 0 0;

  &__row {
    width: 100%;

    @include mq(m) {
      display: flex;
      gap: get-space(xs);
    }

    .tickets-form__item {
      margin-bottom: get-space(m);
      width: 100%;

      &.hidden {
        display: none;
      }

      @include mq(m) {
        margin-bottom: get-space(l);
      }
    }
  }

  &__submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
