.o-header {
  font-family: $font-family-default;
  position: relative;
  display: block;
  background-color: get-color(white, $colors-greyScale);
  height: 80px;

  button,
  div[role='button'],
  .accordion__button {
    border: none;
    background: transparent;
    padding: 0;
    text-align: left;
    cursor: pointer;
  }

  .l-container__wrapper {
    position: static;
  }

  .l-container__grid {
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: baseline;
  }

  @include mq(l) {
    height: 100px;
  }

  &__logo {
    position: relative;
    margin: 25px 0;
    width: 120px;
    height: 30px;

    @include mq(l) {
      width: 175px;
      height: 49px;
    }

    a {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__meta {
    display: flex;
    align-items: center;
    padding-right: 50px;
    flex-shrink: 0;

    @include mq(l) {
      padding-right: 0;
    }
  }

  &__language-switch {
    padding: 10px get-space(xs) 10px 0;
    margin-right: get-space(xs);
    border-right: 1px solid get-color(grey, $colors-greyScale);

    @include mq(l) {
      padding: 10px get-space(s) 10px 0;
      margin-right: get-space(s);
    }

    a {
      font-size: 20px;
      line-height: 1;
    }
  }

  &__search-auto {
    z-index: 4;

    .l-container__wrapper {
      position: relative;
    }

    .l-container__grid {
      justify-content: flex-start;
    }
  }

  &__search-trigger,
  &__burger {
    cursor: pointer;
    transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1);
    border: 0;
    background-color: transparent;
  }

  &__search-trigger {
    display: flex;
    padding: 0;

    .o-header__search-auto & {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;

      @include mq(l) {
        right: 21px;
      }
    }

    &:hover {
      color: get-color(petrol);
    }
  }

  &__burger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $outerSpacingMobile;
    padding: 0;

    @include mq(m) {
      right: $outerSpacingTablet;
    }

    @include mq(l) {
      display: none;
    }

    &:hover {
      color: get-color(petrol);
    }
  }

  &__main-navigation {
    position: absolute;
    background-color: get-color(white, $colors-greyScale);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
    z-index: 3;
    width: 100%;
    left: 0;
    top: 80px;
    padding-bottom: get-space(xxxl);

    &--hidden {
      @include mq($until: l) {
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
      }
    }

    @include mq(l) {
      position: static;
      box-shadow: none;
      background-color: transparent;
      left: auto;
      top: 0;
      padding-bottom: 0;
    }

    .a-link {
      align-items: baseline;
    }
  }

  &__sub-navigation {
    width: 100%;

    &--hidden {
      width: 1px;
      @extend %visually-hidden;
    }
  }

  &__main-list,
  &__sub-list {
    width: 100%;

    &,
    ul {
      margin: 0;
      padding: 0;
    }

    @include mq(l) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__main-entry {
    background-color: get-color(grey-super-light, $colors-greyScale);
    border-bottom: 1px solid get-color(grey, $colors-greyScale);

    @include mq(l) {
      background-color: transparent;
      border-bottom: 0;
    }

    .o-header__panel,
    .accordion__panel {
      background-color: get-color(white, $colors-greyScale);

      @include mq(l) {
        position: absolute;
        padding-top: 50px;
        z-index: 3;
        top: 100px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
        border-top: 1px solid get-color(grey, $colors-greyScale);
        width: 100%;
        left: 0;
      }
    }
  }

  &__sub-headline {
    position: relative;
    display: block;

    @include mq(l) {
      font-size: 20px;
      line-height: 24px;
      color: get-color(grey-dark, $colors-greyScale);
    }
  }

  &__sub-block {
    border-bottom: 1px solid get-color(grey, $colors-greyScale);
    background-color: get-color(white, $colors-greyScale);

    @include use-grid-span(
      'width',
      (
        l: 3 narrow,
        xl: 3 narrow
      )
    );

    &:not(:last-child) {
      @include use-grid-gutter('padding-right', (l, xl), false);
    }

    &--has-image {
      @include use-grid-span(
        'width',
        (
          l: 6 narrow,
          xl: 6 narrow
        )
      );

      .o-header__sub-headline {
        @include use-grid-span(
          'margin-left',
          (
            l: 2 wide of 6 narrow,
            xl: 2 wide of 6 narrow
          )
        );
      }
    }

    @include mq(l) {
      position: relative;
      background-color: transparent;
      border-bottom: none;

      &--has-highlight {
        &:before {
          position: absolute;
          content: '';
          background-color: get-color(grey-super-light, $colors-greyScale);
          width: 100%;
          height: calc(100% + 40px);
          top: -20px;
        }

        span,
        ul,
        .o-header__contact-block {
          padding: 0 get-space(s);
        }
      }
    }

    .accordion__icon {
      transition: all 0.3s ease-in-out;
      width: 20px;
      height: 11px;
      flex-shrink: 0;
      margin-left: get-space(xs);
    }

    .accordion__button[aria-expanded='true'] .accordion__icon {
      transform: rotateX(180deg);
    }
  }

  &__sub-entry,
  .a-copy {
    border-bottom: 0;
    display: block;
    padding: get-space(xs) $outerSpacingMobile;

    @include mq(m) {
      padding: get-space(xs) $outerSpacingTablet;
    }

    @include mq(l) {
      padding: 5px 0 0 0;

      &:first-child {
        padding-top: 20px;
      }
    }
  }

  &__icon-link {
    @include mq(l) {
      padding-top: 20px;
    }
  }

  &__main-label {
    @include mq(l) {
      transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1);
      font-size: 20px;
      line-height: 1;
    }
  }

  &__main-link {
    height: 100%;

    &.contact.active,
    &.contact[aria-expanded='true'] {
      border-bottom: 2px solid get-color(blue-dark);
    }
  }

  &__label-wrapper {
    position: relative;
    padding: get-space(s) $outerSpacingMobile;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @include mq(m) {
      padding: get-space(s) $outerSpacingTablet;
    }

    @include mq(l) {
      display: block;
      z-index: 4;
      padding: get-space(l) get-space(s);
    }

    @include mq(xl) {
      padding: get-space(l) get-space(m);
    }

    .o-header__sub-navigation & {
      @include mq(l) {
        border-top: 1px solid get-color(grey, $colors-greyScale);
        padding: get-space(s) 0;
        margin-top: get-space(l);
        text-align: right;
      }
    }

    .o-header__main-link &:hover,
    .accordion__button &:hover {
      .o-header__main-label {
        color: get-color(petrol);
      }
    }

    .o-header__main-link.contact & {
      @include mq(l) {
        border: 1px solid get-color(blue-night);
        border-radius: 7px;
        padding-top: 18px;
        padding-bottom: 18px;
        top: 20.5px;
      }
    }

    .o-header__main-link.active:not(.contact) &,
    .o-header__main-link[aria-expanded='true']:not(.contact) & {
      @include mq(l) {
        border-bottom: 2px solid get-color(blue-dark);
      }
    }
  }

  // Contact Layer
  &__contact-block {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  &__contact-image {
    position: relative;
    margin-top: -24px;
    display: none;

    @include use-grid-span(
      'width',
      (
        l: 2 of 6 narrow,
        xl: 2 of 6 narrow
      )
    );

    @include use-grid-gutter('margin-right', (l, xl), false);

    @include mq(l) {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  &__content-text {
    width: 100%;
  }
}
