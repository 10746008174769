html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='url'] {
  box-sizing: border-box;
}

html {
  // Prevents iOS text size adjust after orientation change, without disabling
  text-size-adjust: 100%;

  // Optimize text rendering speed
  text-rendering: optimizeSpeed;
}

body {
  color: get-color(blue-night);
  font-family: $font-family-default;
  // enable font smoothing http://szafranek.net/works/articles/font-smoothing-explained/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Reset default UA Styles for typographical elements
figure,
h1,
h2,
h3,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  position: relative;
}

button,
div[role='button'],
.accordion__button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;

  [data-whatinput='mouse'] &,
  [data-whatinput='touch'] & {
    outline: 0;
  }
}

img {
  width: 100%;
  height: auto;
}

.lazyload.no-src,
.lazyloading.no-src {
  opacity: 0;
  transition: opacity 400ms;
}

img.lazyloaded {
  opacity: 1;
  transition: opacity 400ms;
}

// stylelint-disable
input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button,
select {
  color: inherit;
}
