.m-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: get-space(xxl) 0;

  &_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
  }

  &__link {
    @include text-type-tag;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;

    [data-whatinput='mouse'] &,
    [data-whatinput='touch'] & {
      outline: 0;
    }
  }

  &__button {
    display: block;
    position: relative;

    &-arrow,
    &-circle {
      display: block;
    }

    &-arrow {
      color: get-color(blue-dark);
      position: absolute;
      display: inline-flex;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &-circle {
      color: get-color(grey, $colors-greyScale);

      svg {
        width: 42px;
        height: 42px;
      }
    }

    &--is-disabled {
      pointer-events: none;
      user-select: none;
    }

    &--is-disabled &-arrow {
      color: rgba(get-color(blue-dark), 0.4);
    }

    &--is-disabled &-circle {
      color: rgba(get-color(grey, $colors-greyScale), 0.4);
    }
  }

  &__item {
    margin: 0;
    padding: 0;
    margin-top: get-space(s);
    display: none;
    position: relative;

    @include mq(m) {
      display: inline-block;
    }

    // allways show the left and right arrow
    &:first-child,
    &:last-child {
      display: inline-block;
    }

    &:first-child {
      padding-right: 35px;

      .m-pagination__button-arrow svg {
        transform: rotate(180deg);
      }
    }

    &:last-child {
      padding-left: 35px;
    }

    &--show-on-mobile {
      display: inline-block;
    }

    &--is-current .m-pagination__link {
      @include text-type-headline-sub-bold-static;
      color: get-color(petrol);
    }
  }
}
