.m-file-upload {
  $self: &;
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  width: 100%;

  input[type='file'] {
    display: none;
    opacity: 0;
    position: absolute;
  }

  &__button {
    background: transparent;
    cursor: pointer;
    height: auto;
    width: 100%;
    justify-content: space-between;

    svg {
      flex-shrink: 0;
    }
  }

  &__info {
    position: relative;
    display: inline-block;
    color: get-color(petrol);
    width: 100%;
    margin-top: get-space(xs);
    padding-right: get-space(xs);
    cursor: pointer;

    &--hidden {
      display: none;
    }

    &-content {
      display: inline-block;
    }
  }

  &__clear {
    display: inline-block;
    margin-left: get-space(xs);
    color: get-color(petrol);
  }

  &__required {
    font-family: $font-family-default;
    font-size: 30px;
  }

  // error
  &__error {
    position: relative;
    display: none;
    width: 100%;
    @include text-type-copy;
    margin-top: get-space(xxs);
    color: get-color(red, $colors-tertiary);
  }

  &__instructions {
    @include text-type-copy;
    margin-top: get-space(xxs);
  }

  &--error,
  input.user-error {
    #{$self}__error {
      display: block;
    }

    & + #{$self}__button {
      color: get-color(red, $colors-tertiary);
      border-color: get-color(red, $colors-tertiary);

      svg {
        fill: get-color(red, $colors-tertiary);
      }
    }
  }
}
