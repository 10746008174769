.tickets-accordion {
  &__item {
    padding: get-space(m) 0;
  }

  &__button {
    [data-whatinput='mouse'] &,
    [data-whatinput='touch'] & {
      outline: 0;
    }
  }

  &__icon {
    transition: all 0.3s cubic-bezier(0.48, 0, 0.5, 1);
    width: 20px;
    height: 11px;
    flex-shrink: 0;
    margin-left: get-space(xs);
  }

  &__button[aria-expanded='true'] &__icon {
    transform: rotateX(180deg);
  }

  &__panel {
    a {
      margin-top: get-space(s);
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: get-space(xxs);
    border-bottom: 1px solid get-color(blue-dark);
  }
}
