.m-trust-element {
  position: relative;
  border: 1px solid get-color(grey, $colors-greyScale);
  border-left: 0;
  border-right: 0;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: get-space(l) auto;
    @include use-grid-span(
      'width',
      (
        m: 10 narrow
      ),
      (4 narrow)
    );

    @include mq(m) {
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  &__headline {
    width: 100%;
    text-align: center;
    color: get-color(black, $colors-tertiary);
  }

  &__images {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include mq(m) {
      flex-flow: row wrap;
      justify-content: center;
    }
  }

  &__image {
    position: relative;
    height: 60px;
    max-width: 100%;
    width: auto;
    margin-top: 60px;

    &:not(:last-child) {
      @include mq(m) {
        margin-right: 60px;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
