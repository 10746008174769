.o-multi-step-form {
  $self: &;
  background: get-color(blue-pastel);
  padding: get-space(xl) 0;
  margin-bottom: get-space(xl);

  &--petrol-background {
    background: get-color(petrol-light);
  }

  @include mq(m) {
    padding: get-space(xxl) 0;
    margin-bottom: get-space(xxl);
  }

  &__wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    @include mq(m) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    &--centered {
      @include mq(m) {
        align-items: center;
      }
    }
  }

  //  &__icon-wrapper {
  &__image-intro {
    position: relative;
    margin-bottom: get-space(l);

    @include use-grid-span(
      'width',
      (
        m: 4,
        l: 4,
        xl: 4
      ),
      (2)
    );
    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 1 wide,
        xl: 1 wide
      ),
      false
    );

    img {
      width: 100%;
      border-radius: 9999px;
    }
  }

  // &__icon {
  //   width: 100%;
  //   height: 0;
  //   padding-bottom: 100%;
  //   background: get-color(white, $colors-greyScale);
  //   border-radius: 100%;

  //   svg {
  //     position: absolute;
  //     top: 50%;
  //     transform: translateY(-50%);

  //     @include use-grid-span(
  //       'width',
  //       (
  //         m: 2 wide of 4,
  //         l: 2 wide of 4,
  //         xl: 2 wide of 4
  //       ),
  //       (1 wide of 2)
  //     );
  //     @include use-grid-span(
  //       'margin-left',
  //       (
  //         m: 1 wide of 4,
  //         l: 1 wide of 4,
  //         xl: 1 wide of 4
  //       ),
  //       (0.5 of 2)
  //     );
  //   }
  // }
  &__image {
    margin-bottom: get-space(l);
    border-radius: 100%;
    @include use-grid-span(
      'width',
      (
        m: 3,
        l: 2,
        xl: 2
      ),
      (2)
    );
    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );

    @include mq(m) {
      margin-bottom: 0;
    }
  }

  &__result-icon {
    display: none;
    margin-bottom: get-space(s);
    @include use-grid-span(
      'width',
      (
        m: 3 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      (2)
    );
    @include use-grid-span(
      'margin-left',
      (
        m: 1,
        l: 2,
        xl: 2
      ),
      false
    );

    @include mq(m) {
      margin-bottom: 0;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    @include use-grid-span(
      'width',
      (
        m: 6 wider,
        l: 6,
        xl: 6
      ),
      (4 narrow)
    );
    @include use-grid-gutter('margin-left', (m, l, xl), false);
    @include use-grid-gutter('padding-left', (m, l, xl), false);

    button {
      margin-left: 0;

      @include mq(m) {
        margin-top: get-space(xl);
      }
    }
  }

  &__intro {
    margin-top: get-space(s);
    display: none;
  }

  &__form {
    @include use-grid-span(
      'width',
      (
        m: 10,
        l: 8,
        xl: 8
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );

    margin-top: get-space(m);

    @include mq(l) {
      margin-top: get-space(xl);
    }
  }

  &__section {
    display: none;
  }

  &__row {
    display: flex;
    flex-direction: column;

    @include mq(l) {
      flex-direction: row;
      flex-wrap: nowrap;
      margin: get-space(l) auto 0 auto;

      &--wrap {
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  &__overview-item {
    @include mq(l) {
      &:nth-child(n + 3) {
        margin-top: get-space(l);
      }
    }

    button {
      display: flex;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      padding: get-space(xxs) 0;
      border-bottom: 1px solid get-color(petrol);
      color: get-color(petrol);

      p {
        @include mq(l) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      svg {
        margin-left: get-space(xxs);
      }

      &.user-error {
        border-color: get-color(red, $colors-tertiary);
        color: get-color(red, $colors-tertiary);
      }
    }
  }

  &__item,
  &__overview-item {
    @include use-grid-span(
      'width',
      (
        m: 10 of 10,
        l: 4 of 8,
        xl: 4 of 8
      ),
      (4 narrow)
    );

    @include mq($until: l) {
      margin-top: get-space(m);
    }

    &--fullwidth,
    &:only-child {
      @include use-grid-span(
        'width',
        (
          m: 9 of 9,
          l: 9 of 9,
          xl: 8 of 8
        ),
        (4 narrow)
      );
    }

    &--align-right {
      text-align: right;
    }

    &--hidden {
      display: none;
    }
  }

  &__item:nth-child(even) {
    @include use-grid-gutter('margin-left', (l, xl), false);
  }

  &__recaptcha {
    display: inline-block;
    margin-left: -20px;
  }

  &__action-wrapper {
    position: relative;
    width: 100%;

    display: flex;
    align-items: flex-end;

    @include mq(m) {
      display: block;
    }

    &--align-right {
      flex-direction: column-reverse;
      text-align: right;
    }
  }

  &__submit,
  &__action-button {
    display: inline-flex;
    cursor: pointer;
    margin-top: get-space(m);
    margin-left: get-space(s);
    min-width: 200px;
  }

  &__progress-bar {
    position: fixed;
    top: 0;
    height: get-space(xxs);
    width: 0%;
    background-color: get-color(blue-dark);
    transition: width 200ms ease-in-out;
    z-index: 1;

    @include mq(l) {
      height: get-space(xs);
    }
  }

  // error
  &__error {
    @include text-type-copy;
    margin-top: get-space(xxs);
    display: none;
    color: get-color(red, $colors-tertiary);
  }

  &__recaptcha.user-error + &__error {
    display: block;
  }

  // states
  .is-visible {
    display: block;
  }

  &.is-result {
    #{$self}__image,
    #{$self}__quote-icon {
      display: none;
    }

    #{$self}__result-icon {
      display: block;
    }

    #{$self}__wrapper {
      @include mq(m) {
        align-items: center;
      }
    }

    #{$self}__form {
      margin-top: 0;
    }
  }
}
