.o-event-form {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: get-space(xl) auto;
  padding: 0 get-space(xl);
  max-width: 800px;

  &__choice {
    padding: get-space(m);
    background: get-color(blue-night);
    color: #fff;
  }

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  // elements
  &__row,
  &__submit-wrapper {
    width: 100%;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;

    @include mq(l) {
      flex-flow: row nowrap;
      margin: get-space(l) auto 0 auto;

      &:first-child {
        margin: 0 auto;
      }
    }
  }

  &__item {
    @include use-grid-span(
      'width',
      (
        l: 6 narrow
      ),
      (4 narrow)
    );

    @include mq($until: l) {
      margin-top: get-space(m);
    }

    &:nth-child(2) {
      @include mq(l) {
        @include use-grid-gutter('margin-left', (l, xl), false);
      }
    }

    // modifier
    &--fullwidth {
      @include use-grid-span(
        'width',
        (
          m: 12 narrow,
          l: 12 narrow,
          xl: 12 narrow
        ),
        (4 narrow)
      );
    }

    &--align-right {
      text-align: right;
    }

    &--hidden {
      display: none;
    }
  }

  // submit button
  &__submit-wrapper {
    position: relative;
    width: 100%;
    text-align: right;
    margin-top: get-space(m);
  }

  &__submit {
    display: inline-flex;
    cursor: pointer;
  }

  // error
  &__error {
    @include text-type-copy;
    margin-top: get-space(xxs);
    display: none;
    color: get-color(red, $colors-tertiary);
  }

  &__recaptcha.user-error + &__error {
    display: block;
  }

  &__success {
    display: none;
    margin-bottom: get-space(l);
  }

  &--success &__success {
    display: block;
  }

  &--success &__form {
    display: none;
  }
}
