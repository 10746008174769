.o-content-copy {
  position: relative;

  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  &--with-background {
    background: get-color(blue-pastel);

    margin: 0;
    padding: get-space(xl) 0;

    @include mq(l) {
      margin: 0;
      padding: get-space(xxl) 0;
    }
  }

  .a-show-more-button,
  .m-mixed-copy {
    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      )
    );
  }

  .m-mixed-copy {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      )
    );

    p:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: 0;
    }
  }

  .a-show-more-button {
    align-self: flex-start;

    & + .m-mixed-copy {
      margin-top: get-space(m);
    }
  }
}
