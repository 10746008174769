.o-footer {
  color: get-color(grey-bright, $colors-greyScale);

  &__upper {
    padding: get-space(xl) 0;
    background-color: get-color(blue-night);
  }

  &__lower {
    padding-bottom: get-space(m);
    background-color: get-color(blue-dark);
  }

  &__socialmedia {
    margin-top: get-space(m);

    ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin: 0 0 0;
      padding: 0;
      list-style: none;

      @include mq(m) {
        margin: 10px 0 0;
      }
    }

    li {
      margin: get-space(s) 30px 0 0;

      a {
        position: relative;
        display: block;

        svg {
          pointer-events: none;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__group {
    @include use-grid-span(
      'width',
      (
        m: 2 wider,
        l: 2 wide
      ),
      (4 narrow)
    );

    &:not(:first-child) {
      margin-top: get-space(l);

      @include mq(m) {
        margin-left: get-space(m);
        margin-top: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      &:not(:last-child) {
        margin-bottom: get-space(xs);
      }
    }
  }

  &__headline {
    display: block;
    margin-bottom: get-space(s);
    color: get-color(petrol);
  }

  &__copy {
    padding-top: get-space(xl);
    max-width: 100%;
    flex-shrink: 0;

    @include mq(m) {
      padding-top: 48px;
    }
  }

  &__copyright {
    margin-top: get-space(xxl);

    @include mq(m) {
      flex-grow: 1;
      margin-top: get-space(m);
      text-align: right;
    }
  }
}
