.m-link-teaser {
  position: relative;
  display: block;

  @include mq(m) {
    padding: 0 get-space(s);
  }

  @include use-grid-span(
    'width',
    (
      m: 5 narrow,
      l: 3 wider
    ),
    (4 narrow)
  );

  &--medium {
    @include use-grid-span(
      'width',
      (
        m: 5 wider,
        l: 5 wider
      ),
      (4 narrow)
    );
  }

  &__links {
    margin-top: get-space(xs);
  }

  &__link {
    width: 100%;
    justify-content: flex-start;
    border-bottom: 1px solid get-color(grey, $colors-greyScale);
    padding: get-space(xs) 0 get-space(xs) get-space(xs);

    @include mq(m) {
      padding-left: 0;
    }

    &:not(:first-child) {
      padding-top: 14px;
    }
  }
}
