.o-stage-questions {
  position: relative;
  display: block;
  padding-top: get-space(l);
  background-color: get-color(blue-pastel);

  + section {
    margin-top: get-space(l);

    @include mq(l) {
      margin-top: get-space(xl);
    }
  }

  @include mq(l) {
    min-height: 740px;
    padding-top: 50px;
  }

  &__bg {
    position: absolute;
    top: 27.5%;
    left: 0;
    width: calc(#{span(4 narrow)} + #{$outerSpacingMobile});
    height: 100%;
    margin-left: $outerSpacingMobile * -1;

    @include use-grid-span(
      'width',
      (
        l: 8 narrow
      )
    );

    @include mq(m) {
      width: calc(#{span(4 narrow)} + #{$outerSpacingTablet});
      margin-left: $outerSpacingTablet * -1;
    }

    @include mq(l) {
      top: 43px;
      left: 50%;
      height: 563px;
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      width: 0;
      height: 100%;
      background-color: get-color(petrol-light);
      transition: width 400ms cubic-bezier(0.48, 0, 0.5, 1);
    }
  }

  &__wrapper {
    position: relative;
    margin-right: $outerSpacingMobile;
    margin-left: $outerSpacingMobile;

    @include use-grid-span(
      'width',
      (
        l: 6 narrow
      ),
      (4 narrow)
    );

    @include mq(m) {
      margin-right: $outerSpacingMobile;
      margin-left: $outerSpacingMobile;
    }

    @include mq(l) {
      position: static;
      margin: 0;
    }
  }

  &__text {
    display: flex;
    position: relative;
    flex-flow: column nowrap;

    @include use-grid-span(
      'width',
      (
        l: 12 narrow
      ),
      (3 wider)
    );
  }

  &__headline {
    margin-bottom: 20px;

    @include mq(l) {
      margin-top: 90px;
    }
  }

  &__copy {
    margin-bottom: 40px;

    @include use-grid-span(
      'width',
      (
        l: 10 narrow
      )
    );
  }

  &__faq {
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
  }

  &.is-in-viewport {
    .o-stage-questions__bg:after {
      opacity: 1;
      width: 100%;
    }

    .o-stage-questions__faq {
      opacity: 1;
    }
  }
}
