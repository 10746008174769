.m-mixed-copy {
  $self: &;
  color: get-color(blue-dark);

  h1,
  h2 {
    @include text-type-headline-highlight;
    margin-bottom: get-space(l);
  }

  h3 {
    @include text-type-headline-highlight;
    margin-bottom: get-space(m);

    @include mq(m) {
      margin-bottom: get-space(l);
    }
  }

  h4 {
    @include text-type-headline-sub;
    margin-bottom: get-space(s);

    @include mq(m) {
      margin-bottom: get-space(m);
    }
  }

  p {
    @include text-type-copy-focused;
    margin-bottom: get-space(m);
  }

  a,
  button {
    color: get-color('petrol');
    text-decoration: underline;
  }

  strong {
    font-family: $font-family-default-bold;
    font-weight: normal;
  }

  iframe {
    max-width: 100%;
  }

  ol,
  ul {
    @extend .a-list;
    @include text-type-copy-focused;
    list-style: none;
    margin: 0 0 get-space(m);
    padding: 0;
  }

  a.a-cta {
    @extend .a-button, .a-button--primary-grey;
    text-decoration: none;
    left: 50%;
    transform: translateX(-50%);
    margin-top: get-space(m);
  }

  &--copy {
    p,
    ul,
    ol {
      @include text-type-copy;
    }
  }

  &--focused {
    p {
      @include text-type-copy-focused;
    }
  }

  &--searchlist {
    p {
      @include text-type-copy-searchlist;
    }
  }

  &--small {
    p {
      @include text-type-copy-small;
    }
  }

  &--tag {
    p {
      @include text-type-tag;
    }
  }

  &--color-white {
    color: get-color(white, $colors-greyScale);
  }

  &--centered {
    text-align: center;
  }
}
