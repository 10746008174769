.o-search {
  position: relative;
  z-index: 2;
  background-color: get-color(blue-dark);

  @include mq(m) {
    background-color: transparent;
  }

  .o-stage-image & {
    @include mq(m) {
      margin-top: -50px;
    }

    @include mq(l) {
      margin-top: -80px;
    }
  }
}
