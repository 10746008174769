.a-radio-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    margin-top: get-space(m);
  }

  > label {
    @include text-type-tag;
    width: 100%;
    margin-top: 0;
  }
}
