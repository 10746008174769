.a-link {
  $self: &;
  @include text-type-copy-static;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: get-color('blue-dark');
  transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1);
  word-break: break-word;
  hyphens: auto;

  &__icon {
    width: 9px;
    height: 15px;
    margin-right: 12px;
    transition: transform 250ms cubic-bezier(0.48, 0, 0.5, 1);
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    #{$self}__icon {
      transform: translateX(5px);
    }
  }

  &--desktop-big {
    @include mq(m) {
      font-size: 18px;
    }
  }

  &--grey-bright {
    color: get-color(grey-bright, $colors-greyScale);
  }

  &--white {
    color: get-color(white, $colors-greyScale);
  }

  &--petrol {
    color: get-color(petrol);
  }

  &--hover-opacity {
    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  &--hover-petrol-light {
    &:hover,
    &:focus {
      color: get-color(petrol-light);
    }
  }

  &--hover-petrol {
    &:hover,
    &:focus {
      color: get-color(petrol);
    }
  }
}
