.m-related-topics {
  order: 2;
  position: relative;
  display: block;
  margin-top: get-space(l);

  @include use-grid-span(
    'width',
    (
      m: 8 narrow,
      l: 8 narrow,
      xl: 2 wide
    )
  );

  @include use-grid-span(
    'margin-left',
    (
      m: 2 wide,
      l: 2 wide
    )
  );

  @include mq(l) {
    margin-top: get-space(xl);
  }

  @include mq(xl) {
    padding-left: get-space(s);
    margin-left: 0;
    margin-top: 0;
  }

  &__headline {
    @include text-type-copy-focused;

    &:after {
      background-color: get-color(petrol);
    }
  }

  &__links {
    display: flex;
    flex-flow: row wrap;
  }

  &__link {
    height: 100%;
    padding: 4px get-space(s);
    width: 100%;
    word-break: break-word;
    hyphens: auto;

    @include mq(s) {
      width: auto;
      margin-right: get-space(s);
    }

    @include mq(xl) {
      width: 100%;
      margin-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: get-space(s);
    }

    span {
      @include text-type-copy-searchlist;
    }
  }
}
