.o-anchor-link-navigation {
  position: relative;
  margin: get-space(xl) 0;
  padding: get-space(s) 0 get-space(m);
  background-color: get-color(grey-super-light, $colors-greyScale);

  @include mq(m) {
    padding: get-space(m) 0 get-space(l);
  }

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  &__headline {
    margin: 0 auto get-space(s) auto;
    @include use-grid-span(
      'width',
      (
        m: 12 narrow,
        l: 8 narrow
      ),
      (4)
    );

    @include mq(m) {
      margin: 0 auto get-space(s) auto;
    }
  }

  &__list {
    @include text-type-copy-searchlist;
    list-style: none;
    display: block;
    margin: 0 auto;
    padding: 0;

    @include use-grid-span(
      'width',
      (
        m: 12 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4)
    );
  }

  &__item {
    display: block;
    position: relative;
    padding-left: get-space(s);
    margin-top: get-space(xs);
    @include use-grid-span(
      'width',
      (
        m: 6 of 12 narrow,
        l: 4 of 8 narrow,
        xl: 4 of 8 narrow
      )
    );

    a {
      color: get-color(blue-night);
      text-decoration: underline;
      transition: color 250ms cubic-bezier(0.48, 0, 0.5, 1);

      &:hover,
      &:active,
      &:focus {
        color: get-color(blue-dark);
      }
    }

    @include mq(m) {
      margin-top: get-space(s);
    }

    &:before {
      position: absolute;
      left: 0;
      content: '\2022';
      color: get-color(petrol);
      line-height: 18px;
      top: 5px;
    }
  }

  ol.o-anchor-link-navigation__list {
    counter-reset: item;

    & > li {
      counter-increment: item;
      padding-left: get-space(m);

      &:before {
        content: counter(item) '.';
        @include text-type-copy-focused-bold;
        top: -1px;
        margin-right: 10px;

        @include mq(m) {
          top: -4px;
        }
      }
    }
  }
}
