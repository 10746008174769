.o-stage-plain {
  $self: &;
  position: relative;
  display: block;
  padding-top: 50px;
  padding-bottom: get-space(xxl);

  background-color: get-color(blue-pastel);

  + section {
    margin-top: get-space(l);

    @include mq(l) {
      margin-top: get-space(xl);
    }
  }

  &__bg-1,
  &__bg-2 {
    position: absolute;
    opacity: 0;
    left: -$outerSpacingMobile;

    @include mq(m) {
      left: -$outerSpacingTablet;
    }

    @include mq(l) {
      height: 100%;
      left: 0;
    }
  }

  &__bg-1 {
    transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
    background-color: get-color(petrol-light);
    height: calc(100% - 120px);
    width: calc(100% + #{$outerSpacingMobile});
    top: get-space(l);

    @include mq(m) {
      height: calc(100% - 200px);
      width: calc(100% + #{$outerSpacingTablet});
    }

    @include mq(l) {
      top: get-space(xxl);
    }

    @include use-grid-span(
      'width',
      (
        l: 8 narrow,
        xl: 8 narrow
      )
    );

    @include use-grid-span(
      'margin-left',
      (
        l: 2 wide,
        xl: 2 wide
      )
    );
  }

  &__bg-2 {
    position: relative;
    width: calc(100% + #{$outerSpacingMobile} * 2);
    height: 120px;

    @include mq(m) {
      width: calc(100% + #{$outerSpacingTablet} * 2);
      height: 200px;
    }

    @include use-grid-span(
      'width',
      (
        l: 5 wider,
        xl: 5 wider
      )
    );

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      transition: width 400ms cubic-bezier(0.48, 0, 0.5, 1);
      background-color: get-color(blue-dark);
    }

    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
      background-color: get-color(petrol);
      height: 80px;
      width: 100%;
      left: -$outerSpacingMobile;

      @include mq(m) {
        left: -$outerSpacingTablet;
      }

      @include mq(l) {
        height: calc(100% - #{get-space(xxl)});
        top: get-space(xxl);
        left: 0;

        @include use-grid-span(
          'width',
          (
            l: 4 wide of 5 wider,
            xl: 4 wide of 5 wider
          )
        );
      }
    }
  }

  &__image-wrapper {
    display: block;

    @include mq(l) {
      margin-top: get-space(l);
    }

    @include use-grid-span(
      'width',
      (
        m: 4 wider,
        l: 4 wider,
        xl: 3 wider
      )
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 1 wide,
        xl: 1 wide
      )
    );
  }

  &__image-inner-wrapper {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;

    &:before {
      @include mq(m) {
        transition: width 400ms cubic-bezier(0.48, 0, 0.5, 1) 450ms;
        content: '';
        position: absolute;
        width: 0;
        height: 100%;
        background: get-color(petrol);

        @include use-grid-gutter('left', (m, l, xl), true);
        @include use-grid-gutter('top', (m, l, xl), true);
      }
    }
  }

  &__image {
    position: absolute;
    transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1) 850ms;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: -$outerSpacingMobile;

    @include mq(m) {
      left: 0;
    }
  }

  &__content,
  &__text {
    position: relative;
  }

  &__content {
    width: 100%;

    @include mq(l) {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: get-space(xxl);
    }
  }

  &__text {
    margin-bottom: get-space(l);

    @include mq(l) {
      margin-bottom: 0;
      margin-top: 140px;
    }

    @include use-grid-gutter('padding-right', (), true);

    @include use-grid-span(
      'width',
      (
        l: 6 narrow,
        xl: 6 narrow
      )
    );
  }

  &__headline {
    margin-top: 0;
    margin-bottom: get-space(s);
  }

  &__copy {
    position: relative;

    @include use-grid-span(
      'width',
      (
        l: 5 wider of 6 narrow,
        xl: 5 wider of 6 narrow
      )
    );
  }

  &--has-image {
    #{$self}__content {
      @include mq(m) {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: get-space(xxl);
      }
    }

    #{$self}__bg-1 {
      transition: opacity 400ms cubic-bezier(0.48, 0, 0.5, 1);
      @include mq(m) {
        height: calc(100% - 40px);
      }

      @include mq(l) {
        height: 100%;
      }
    }

    #{$self}__text {
      @include use-grid-span(
        'width',
        (
          m: 6 narrow
        )
      );
    }

    #{$self}__text #{$self}__copy {
      @include mq($until: m) {
        display: none;
      }

      @include use-grid-span(
        'width',
        (
          m: 5 wider of 6 narrow
        )
      );
    }

    #{$self}__content > #{$self}__copy {
      margin-top: get-space(m);
      padding-right: get-space(s);

      @include mq(m) {
        display: none;
      }
    }
  }

  &.is-in-viewport {
    .o-stage-plain__bg-1,
    .o-stage-plain__bg-2,
    .o-stage-plain__bg-2:after,
    .o-stage-plain__image {
      opacity: 1;
    }

    .o-stage-plain__bg-2:before,
    .o-stage-plain__image-inner-wrapper:before {
      width: 100%;
    }
  }
}
