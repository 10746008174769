.m-content-teaser {
  $self: &;
  position: relative;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .l-container__grid > div {
    width: 100%;
  }

  a:hover #{$self}__button {
    background-position-x: 100%;
  }

  &__wrapper {
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    width: 100%;

    @include mq(m) {
      flex-flow: row wrap;
      align-items: flex-end;
    }

    @include mq(l) {
      display: block;
    }

    &:before,
    &:after {
      display: table;
      content: '';
      clear: both;
    }
  }

  &__image {
    width: calc(100% + #{$outerSpacingMobile});
    @include use-grid-span(
      'width',
      (
        m: 6 narrow,
        l: 6 narrow,
        xl: 6 narrow
      ),
      (4 narrow)
    );

    @include mq(l) {
      float: left;
    }
  }

  &__upper {
    @include use-grid-span(
      'width',
      (
        m: 6 narrow,
        l: 6 narrow,
        xl: 6 narrow
      ),
      (4 narrow)
    );

    margin-top: get-space(m);

    @include mq(m) {
      margin-top: 0;
      padding-left: 40px;
    }

    @include mq(l) {
      float: left;
      margin-top: 100px;
      padding-left: calc(40px + #{gutter()});
    }

    @include mq(xl) {
      padding-left: calc(40px + #{gutter()});
    }
  }

  &__lower {
    @include use-grid-span(
      'width',
      (
        m: 12 narrow,
        l: 6 narrow,
        xl: 6 narrow
      ),
      (4 narrow)
    );

    @include use-grid-gutter('padding-left', (m), false);
    @include use-grid-span(
      'padding-right',
      (
        m: 1 wide
      ),
      false
    );

    @include mq(l) {
      float: left;
      padding-left: calc(40px + #{gutter()});
      padding-right: 0;
    }

    @include mq(xl) {
      padding-left: calc(40px + #{gutter()});
    }
  }

  &__headline,
  &__copy {
    color: get-color(blue-dark);
  }

  &__headline {
    &:not(:first-child) {
      margin-top: get-space(s);
    }
  }

  &__tag {
    @include text-type-tag;
  }

  &__copy {
    width: 100%;
    margin: 0 0 get-space(m);
    padding: 0;

    @include mq(m) {
      width: 100%;
      margin: get-space(s) 0 get-space(l);
    }

    @include mq(l) {
      width: 100%;
      margin: 0 0 get-space(xl);
    }
  }

  &__links {
    width: 100%;
    margin: 0 0 get-space(m);

    @include mq(m) {
      margin: get-space(s) 0 get-space(l);
    }

    @include mq(l) {
      width: 100%;
      margin: 0 0 get-space(xl);
    }

    a {
      @include text-type-copy-searchlist();
    }
  }
}
