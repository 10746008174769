.o-teaser-row {
  position: relative;
  display: block;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .l-container__grid {
    justify-content: center;
  }

  &__headline {
    text-align: center;
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 6 narrow,
        xl: 6 narrow
      )
    );
  }

  & .a-headline--with-deco:after {
    margin-right: auto;
    margin-left: auto;
  }

  &__teasers {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .m-news-teaser--no-image {
      align-self: center;
    }

    @include mq($until: l) {
      justify-content: space-between;
    }
  }

  &__teaser {
    &:not(.m-content-teaser) {
      @include mq(l) {
        @include use-grid-gutter('margin-left', (), true);
      }
    }

    &:not(:nth-child(3n + 1)) {
      @include use-grid-span(
        'margin-left',
        (
          l: 1 narrow
        )
      );
    }

    & + & {
      margin-top: get-space(xl);

      @include mq(m) {
        margin-top: 0;
      }

      &:not(:nth-child(2)) {
        @include mq(m) {
          margin-top: get-space(xxl);
        }

        @include mq(l) {
          margin-top: 0;
        }
      }
    }

    &.m-content-teaser {
      margin: 0;
    }
  }
}
