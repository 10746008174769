.a-headline {
  display: block;
  @include text-type-headline-main;

  &--sub {
    @include text-type-headline-sub;
  }

  &--sub-static {
    @include text-type-headline-sub;
  }

  &--highlight {
    @include text-type-headline-highlight;
  }

  &--theme-white {
    color: get-color(white, $colors-greyScale);
  }

  &--with-deco {
    &:after {
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      margin: 20px 0 30px;
      background-color: get-color(grey-light, $colors-greyScale);

      @include mq(m) {
        margin: 20px 0 13px;
      }

      @include mq(l) {
        margin: 20px 0 40px;
      }
    }
  }
}
