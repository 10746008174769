$z-index-offset: 0;
$z-index-map-default: (
  'root': (
    'header': 1,
    'm-cookie-layer': 1,
    'o-modal': 100,
    'o-video': 0
  ),
  'header': (
    'main-nav': 1,
    'search': 1
  ),
  'o-video': (
    'poster': 1,
    'embed-container': 2,
    'button': 3
  )
);

/**
 * Looks up the passed z-item in a z-group in a z-map and returns its z-index.
 * A z-map has to be defined in settings containing z-groups that contain z-items with a z-index as value each.
 * With the $z-index-offset parameter the z-index of each z-item can be increased.
 * User this function like this:
 * z-index: get-z-index('header'); // Returns the correct base z-index for the navigation
 * z-index: get-z-index('search', 'header'); // In this case navigation has its own z-index-group defined and it will return the z-index of the nav-item of the navigation
 * @param  {string} $z-item Identifier of the z-item from configuration
 * @param  {string} $z-group Identifier of the z-group. By default this is the "root"-group
 * @param  {map} $z-map The z-map configuration map
 * @return {number} Resulting z-index
 */
@function get-z-index($z-item, $z-group: root, $z-map: $z-index-map-default) {
  // Check if z-group is available in z-map
  @if map-has-key($z-map, $z-group) {
    // Check if z-item is available in z-group
    @if map-has-key(map-get($z-map, $z-group), $z-item) {
      @return map-get(map-get($z-map, $z-group), $z-item) + $z-index-offset;
    } @else {
      @warn 'z-item '#{$z-item}' not found!';
    }
  } @else {
    @warn 'z-group '#{$z-group}' not found!';
  }
}
