.o-overview {
  $self: &;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  &__headline {
    position: relative;
    @include use-grid-span(
      'width',
      (
        m: 10 narrow,
        l: 5 narrow
      ),
      (4 narrow)
    );

    @include mq(m) {
      margin: 0 auto;
      text-align: center;
    }

    &:after {
      margin-bottom: get-space(s);
      @include mq(m) {
        margin: get-space(s) auto 0;
      }

      @include mq(l) {
        margin: get-space(s) auto 0;
      }
    }
  }

  &__items {
    display: flex;
    flex-flow: row wrap;
    margin-top: get-space(xxl);
  }

  &__item {
    position: relative;
    display: flex;
    @include use-grid-span(
      'width',
      (
        m: 6 narrow,
        l: 5 narrow,
        xl: 5 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        l: 1 wide
      ),
      false
    );

    &:nth-child(2n) {
      @include use-grid-gutter('margin-left', (m), false);

      @include use-grid-span(
        'margin-left',
        (
          l: 1 wider,
          xl: 1 wider
        ),
        false
      );
    }

    & + #{$self}__item {
      margin-top: get-space(l);

      @include mq(m) {
        margin-top: 0;
      }

      &:not(:nth-child(2)) {
        @include mq(m) {
          margin-top: get-space(xl);
        }
      }
    }

    &-content {
      @include mq(m) {
        padding-right: get-space(s);
      }

      @include mq(l) {
        padding-right: 0;
      }

      span {
        margin-bottom: get-space(xs);

        @include mq(m) {
          margin-bottom: get-space(s);
        }
      }
    }

    &-icon,
    &-index {
      width: 40px;
      flex-shrink: 0;
      margin-right: get-space(s);
      text-align: center;

      @include mq(l) {
        margin-right: get-space(m);
        width: 90px;
      }

      svg {
        width: 100%;
        margin-top: get-space(xs);
        color: get-color(blue-night) !important;
        fill: get-color(blue-night) !important;

        path {
          color: get-color(blue-night) !important;
          fill: get-color(blue-night) !important;
        }
      }

      span {
        font-size: 60px;
        line-height: 1;
        font-family: $font-family-soleil;
        color: get-color(blue-night);

        @include mq(l) {
          font-size: 135px;
          line-height: 0.85;
        }
      }
    }

    &:before {
      height: get-space(l);
      width: 85%;
      content: '';
      position: absolute;
      top: calc(#{get-space(xs)} * -1);
      left: calc(#{get-space(xs)} * -1);
      background: get-color(petrol-light);
      z-index: -1;

      @include mq(l) {
        height: get-space(xxl);
        top: calc(#{get-space(m)} * -1);
      }
    }
  }

  &__button {
    margin-top: get-space(l);

    @include mq(m) {
      margin-top: 50px;
      text-align: center;
    }
  }
}
