.o-content-image {
  position: relative;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .o-content-copy + &,
  & + .o-content-copy {
    margin-top: -#{get-space(m)};

    @include mq(l) {
      margin-top: -50px;
    }
  }

  &__wrapper {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      )
    );
  }

  &__image-wrapper {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 56.26%;
    top: 0;
    left: 0;
  }

  &__image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__caption {
    font-style: italic;
  }
}
