.o-person-contact {
  $self: &;
  background: get-color(blue-pastel);
  padding-top: get-space(xl);
  margin-bottom: get-space(xl);

  @include mq(m) {
    padding-top: get-space(xxl);
    margin-bottom: get-space(xxl);
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;

    @include mq(m) {
      flex-flow: row nowrap;
      align-items: flex-start;
    }
  }

  &__image {
    @include use-grid-span(
      'width',
      (
        m: 3 narrow,
        l: 2 narrow,
        xl: 2 narrow
      ),
      (2 narrow)
    );
    @include use-grid-span(
      'margin-left',
      (
        m: 1 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );
    margin-bottom: get-space(l);
    border-radius: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;

    @include use-grid-span(
      'width',
      (
        m: 6 wider,
        l: 6 wider,
        xl: 6 wider
      ),
      (4 narrow)
    );
    @include use-grid-gutter('margin-left', (m, l, xl), false);
  }

  &__copy,
  &__headline {
    margin-top: get-space(s);
  }

  &__button {
    @include text-type-copy-focused;
    display: block;
    cursor: pointer;
    width: 100%;
    height: 62px;
    border: 0;
    background: get-color(petrol);
    margin-top: get-space(l);
    color: get-color(white, $colors-greyScale);

    .l-container__grid {
      justify-content: space-between;
      align-items: center;
    }
  }

  &__button-label {
    display: flex;
    align-items: center;
    @include use-grid-span(
      'margin-left',
      (
        m: 3 wide,
        l: 4 wide,
        xl: 4 wide
      ),
      false
    );

    svg {
      margin-right: get-space(xs);
    }
  }

  &__button-icon {
    width: 22px;
    height: 20px;

    #{$self}--form-is-open & {
      transform: rotate(180deg);
    }
  }

  &__form-wrapper {
    margin-top: get-space('l');
    display: none;

    #{$self}--form-is-open & {
      display: block;
    }

    form {
      margin-top: get-space('l');
    }
  }

  &__button-close {
    cursor: pointer;
    color: get-color(petrol);
    background: none;
    border: 0;
    padding: 0;
    align-self: flex-end;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__form-headline {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );
  }

  &--dark {
    background: get-color(blue-night);
    color: get-color(white, $colors-greyScale);

    .a-copy--tag,
    #{$self}__button-close {
      color: get-color(white, $colors-greyScale);
    }

    #{$self}__form-wrapper {
      .a-select {
        &__placeholder,
        &__select {
          color: get-color(white, $colors-greyScale);
          border-color: get-color(white, $colors-greyScale);

          &:hover:not([disabled]) {
            color: get-color(petrol-light);
            border-color: get-color(petrol-light);
          }
        }

        &__item {
          &:hover,
          &:focus {
            color: get-color(petrol-light);
          }
        }

        &__select {
          &.user-error,
          &.user-error + .a-select__custom {
            &,
            .a-select__placeholder {
              color: get-color(red-light, $colors-tertiary);
              border-color: get-color(red-light, $colors-tertiary);
            }

            ~ .a-select__icon {
              color: get-color(red-light, $colors-tertiary);
            }
          }
        }
      }

      .a-input input,
      .a-textarea__textarea {
        border-color: get-color(white, $colors-greyScale);
        color: get-color(petrol-light);

        &:focus {
          border-color: get-color(petrol-light);
        }

        &::placeholder {
          color: get-color(white, $colors-greyScale);
        }

        &.user-error {
          color: get-color(red-light, $colors-tertiary);
          border-color: get-color(red-light, $colors-tertiary);

          ~ .a-input__required,
          ~ .a-textarea__required {
            color: get-color(red-light, $colors-tertiary);
          }

          &::placeholder {
            color: get-color(red-light, $colors-tertiary);
          }
        }
      }

      .a-checkbox {
        &__input {
          + .a-checkbox__label,
          + .a-checkbox__label:before {
            color: get-color(white, $colors-greyScale);
            border-color: get-color(white, $colors-greyScale);
          }

          &.user-error {
            + .a-checkbox__label,
            + .a-checkbox__label:before {
              color: get-color(red-light, $colors-tertiary);
              border-color: get-color(red-light, $colors-tertiary);
            }
          }
        }

        &__input:checked {
          + .a-checkbox__label,
          + .a-checkbox__label:before {
            color: get-color(petrol-light);
            border-color: get-color(petrol-light);
          }
        }

        &__input:focus {
          + .a-checkbox__label {
            color: get-color(petrol-light);
          }
        }

        &__description a {
          color: get-color(petrol-light);
        }

        &__label:hover:not([disabled]) {
          color: get-color(petrol-light);
        }
      }

      .a-input__error,
      .a-select__error,
      .a-textarea__error,
      .a-checkbox__error,
      .o-form__error {
        color: get-color(red-light, $colors-tertiary);
      }

      .m-file-upload__button {
        &,
        svg {
          color: get-color(white, $colors-greyScale);
        }

        &:hover {
          border-color: get-color(petrol-light);
        }
      }
    }
  }
}
