// TODO: Cleanup, need to use spacing mixins more..
.a-search-auto {
  $self: &;
  position: relative;
  margin-bottom: get-space(l);

  &__wrapper {
    display: flex;
    position: relative;
    flex-flow: row nowrap;
    align-items: stretch;
    height: 60px;

    > .a-svg-sprite--default-search {
      position: absolute;
      top: 15px;
      left: 12px;

      @include mq(m) {
        left: 21px;
      }
    }
  }

  &__container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;

    &--open {
      #{$self}__input,
      + #{$self}__button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &__input {
    font-family: $font-family-default;
    font-size: 20px;
    line-height: normal;
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding: 0 10px 0 54px;
    width: 100%;
    height: 100%;
    background-color: get-color(white, $colors-greyScale);
    word-break: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq(m) {
      padding: 0 10px 0 63px;
    }

    &::placeholder {
      color: get-color(grey-dark, $colors-greyScale);
    }

    &:focus {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }

    &[aria-controls] {
      cursor: text;
    }
  }

  &__button {
    margin-left: -1px;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__suggestions-container {
    display: none;

    &--open {
      @include text-type-copy-searchlist;
      display: block;
      width: 100%;
      position: absolute;
      padding: 6px 0;
      top: 60px;
      border-top: 1px solid get-color(grey, $colors-greyScale);
      background-color: #fff;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5);
      font-family: Helvetica, sans-serif;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: 2;
    }
  }

  &__section-container {
    border-top: 1px solid get-color(grey, $colors-greyScale);

    &--first {
      border-top: 0;
    }
  }

  &__suggestions-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__suggestion {
    cursor: pointer;
    padding: 18px get-space(xs);

    @include mq(m) {
      padding: 18px get-space(s);
    }

    &--highlighted {
      background-color: get-color(blue-pastel);
    }

    .a-svg-sprite {
      position: relative;
      margin-right: 22px;
      flex-shrink: 0;

      @include mq(m) {
        margin-right: get-space(m);
      }
    }
  }

  &__suggestion-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__suggestion-text {
    display: flex;
    align-items: flex-start;
  }

  &__suggestion-entry {
    text-transform: uppercase;
    display: none;

    @include mq(m) {
      display: block;
    }
  }

  &--larger-input {
    @include mq(m) {
      #{$self}__wrapper {
        height: 76px;

        > .a-svg-sprite {
          top: 20px;
        }
      }

      #{$self}__input {
        font-size: 26px;
      }

      #{$self}__button {
        height: 76px;
        font-size: 20px;
      }

      #{$self}__suggestions-container--open {
        top: 76px;
      }
    }
  }

  &--navigation-input {
    position: absolute;
    margin-bottom: 0;
    right: 0;
    top: 20px;
    width: 100%;

    @include mq(l) {
      top: 21px;
    }

    #{$self}__wrapper {
      height: 40px;
      width: 100%;

      @include mq(l) {
        height: 58px;
        margin-left: 20%;
      }

      > .a-svg-sprite--default-search {
        width: 21px;
        height: 22px;
        top: 10px;

        @include mq(l) {
          top: 15px;
        }
      }
    }

    #{$self}__input {
      border: 1px solid get-color(grey, $colors-greyScale);
      border-radius: 8px;
      padding-right: 54px;
    }

    #{$self}__button {
      display: none;
    }
  }
}
