.o-content-video {
  position: relative;
  z-index: get-z-index('o-video');
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .o-content-copy + &,
  & + .o-content-copy {
    margin-top: -#{get-space(m)};

    @include mq(l) {
      margin-top: -50px;
    }
  }

  &__wrapper {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      )
    );
  }

  &--has-highlight &__video {
    left: -#{get-space(m)};
    width: calc(100% + #{get-space(m)});
    margin-top: get-space(s);

    @include mq(m) {
      left: auto;
      width: 100%;
      margin-top: get-space(m);
    }

    &:before {
      content: '';
      position: absolute;
      top: -#{get-space(s)};
      width: 100vw;
      height: 100%;
      background-color: get-color(petrol-light);

      @include use-grid-span(
        'margin-left',
        (
          m: 1 wider of 8,
          l: 1 wider of 8,
          xl: 1 wider of 8
        )
      );

      @include mq(m) {
        width: 100%;
        top: -#{get-space(m)};
      }
    }
  }

  &__caption {
    margin-top: get-space(xs);
    font-style: italic;
  }
}
