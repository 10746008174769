.o-lightbox-image {
  position: relative;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .o-content-copy + &,
  & + .o-content-copy {
    margin-top: -#{get-space(m)};

    @include mq(l) {
      margin-top: -50px;
    }
  }

  &__wrapper {
    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      )
    );
  }

  &__image-wrapper {
    display: block;
    position: relative;
    text-align: center;
  }

  &__image {
    width: 100%;
    display: none;

    @include mq(m) {
      display: block;
    }
  }

  &__mobile-image {
    width: 100%;
    display: block;

    @include mq(m) {
      display: none;
    }
  }

  &__caption {
    font-style: italic;
  }

  & .a-headline--with-deco:after {
    margin-left: auto;
    margin-right: auto;
  }

  &__headline {
    text-align: center;
    margin-top: get-space(m);

    @include use-grid-span(
      'width',
      (
        m: 8 narrow,
        l: 8 narrow,
        xl: 8 narrow
      ),
      (4 narrow)
    );

    @include use-grid-span(
      'margin-left',
      (
        m: 2 wide,
        l: 2 wide,
        xl: 2 wide
      ),
      false
    );
  }

  &__lightbox-button {
    margin-top: get-space(s);
  }
}
