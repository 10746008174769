.m-breadcrumbs {
  @extend %visually-hidden;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 100px;

  @include mq(m) {
    height: auto;
    overflow: auto;
    clip: auto;
  }

  @include mq(l) {
    top: 120px;
  }

  &__list {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: flex;
    // A very high flex-shrink prevents the last-child (with flex-shrink: 1)
    // from shrinking until it is really necessary.
    flex: 0 99999 auto;
    // Min width prevents the collapsed items from disappearing completely
    // With this there will always be a "... >" at least.
    min-width: 7px;
    align-items: center;

    &:last-child {
      flex: 0 1 auto;
    }
  }

  &__breadcrumb {
    @include text-type-copy-small;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0 1 auto;
  }

  &__spacer {
    display: inline-block;
    margin: 0 get-space(xs);
    flex: 0 0 auto;
  }
}
