.m-banner {
  position: relative;
  width: 100%;
  padding: get-space(m) 0;
  background: get-color(blue-dark);
  cursor: pointer;

  .l-container__grid {
    align-items: center;
    justify-content: space-between;
  }

  &__headline {
    color: get-color(white, $colors-greyScale);
    margin-bottom: get-space(m);

    @include use-grid-span(
      'width',
      (
        m: 10,
        l: 8
      ),
      (3 wider)
    );

    @include mq(l) {
      margin-bottom: 0;
    }
  }

  &__confirm {
    @include use-grid-span(
      'margin-right',
      (
        l: 1 wide
      )
    );
  }

  &__close {
    position: absolute;
    color: get-color(petrol);
    background: none;
    border: 0;
    padding: 0;
    right: 0;
    top: 0;
    cursor: pointer;

    svg {
      pointer-events: none;
    }
  }
}
