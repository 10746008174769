.o-newsletter-form { 
    background: get-color(blue-pastel);
    width: 100%;
    padding: get-space(xl) 0;
    margin: 0 auto get-space(xl) auto;

    &--no-background {
        background: transparent;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 get-space(l);

        @include mq(m) {
            grid-template-columns: 0.5fr 1fr;
        }
    }

    &__copy {
        margin: get-space(s) 0;
    }

    &__icon {
        margin: 0 0 0 0;
        width: 235px;
        height: 160px;
        margin: 0 0 get-space(l) 0;

        @include mq(m) {
            margin: get-space(l) get-space(l) 0 auto;
            grid-template-columns: 0.5fr 1fr;
        }
    }

    &__form-wrapper {
        section.o-form, .l-container {
            margin: 0;
        }

        .o-form__row {
            margin: 0;
            width: 100%;
            flex-flow: column;
        }

        .o-form__submit-wrapper {
            margin: get-space(m) 0;
            width: fit-content;
        }

        .o-form__item {
            width: 100%;
            margin: 1rem 0;
        }

        .o-form__item:nth-child(2) {
            margin-left: 0;
        }
      }

}