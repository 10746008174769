.a-textarea {
  $self: &;
  @include text-type-copy-focused;
  position: relative;
  width: 100%;

  // elements
  &__textarea {
    width: 100%;
    resize: none;
    padding: get-space(xs);
    background-color: transparent;
    border: 1px solid get-color(blue-dark);

    &::placeholder {
      color: get-color(blue-dark);
      opacity: 1;
    }

    &:hover::placeholder {
      color: get-color(petrol);
    }

    &:focus::placeholder {
      color: transparent;
    }

    &:focus {
      border-color: get-color(petrol);
      outline: 0;
    }
  }

  // required
  &__required {
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: $font-family-default;
    font-size: 30px;
  }

  // error
  &__error {
    @include text-type-copy;
    display: none;
    margin-top: get-space(xxs);
    color: get-color(red, $colors-tertiary);

    .is-visible {
      display: block;
    }
  }

  &--error,
  textarea.user-error {
    #{$self}__error {
      display: block;
    }

    #{$self}__select,
    #{$self}__placeholder,
    ~ #{$self}__required {
      color: get-color(red, $colors-tertiary);
    }

    &:hover:not([disabled]) #{$self}__select,
    &:hover:not([disabled]) #{$self}__placeholder {
      color: get-color(red, $colors-tertiary);
    }
  }

  &--error textarea,
  textarea.user-error {
    border: 1px solid get-color(red, $colors-tertiary);
  }

  textarea.user-error::placeholder {
    color: get-color(red, $colors-tertiary);
  }
}
