.m-social-embed {
  width: 100%;
  position: relative;
  margin-bottom: get-space(m);

  @include mq(m) {
    margin-bottom: get-space(l);
  }

  &--instagram {
    & iframe {
      width: 100% !important;
      min-width: auto !important;
      max-width: 650px !important;
    }
  }

  .fb-post {
    & > span {
      width: 100% !important;
    }

    & iframe {
      width: 100% !important;
      max-width: 650px !important;
      min-width: auto !important;
    }
  }
}
