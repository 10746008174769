.a-radio {
  $self: &;
  position: relative;

  // elements
  &__input {
    @extend %visually-hidden;
  }

  &__label {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    @include text-type-copy-focused;

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      content: '';
      width: 32px;
      height: 32px;
      margin-right: get-space(s);
      border: 1px solid get-color(blue-dark);
      border-radius: 100%;
      flex-shrink: 0;
    }

    span {
      margin-top: 3px;

      @include mq(m) {
        margin-top: 0;
      }
    }

    #{$self}__input:checked + & {
      color: get-color(petrol);

      &__required {
        color: get-color(petrol);
      }

      &:before {
        content: '';
        border: 1px solid get-color(petrol);
        padding-top: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        width: 12px;
        height: 12px;
        background-color: get-color(petrol);
        border-radius: 100%;
      }
    }

    [data-whatintent='keyboard'] #{$self}__input:focus + & {
      color: get-color(petrol);

      &:before {
        @extend %emulate-focus;
      }
    }

    #{$self}__input:disabled + & {
      color: get-color(grey, $colors-greyScale);

      &:before {
        border: 1px solid get-color(grey, $colors-greyScale);
      }
    }
  }

  // hover states
  &:hover:not([disabled]) &__label {
    color: get-color(petrol);
    cursor: pointer;
  }

  // required star
  &__required {
    position: relative;
    top: -8px;
    margin-left: get-space(xxs);
    font-family: $font-family-default;
    font-size: 30px;
  }

  // error
  &__error {
    @include text-type-copy;
    display: none;
    margin-top: get-space(xxs);
    margin-left: calc(40px + #{get-space(s)});
    color: get-color(red, $colors-tertiary);
  }

  &--error,
  input.user-error,
  input.user-error + #{$self}__label {
    color: get-color(red, $colors-tertiary);

    &:hover:not([disabled]) #{$self}__label {
      color: get-color(red, $colors-tertiary);
    }

    & + #{$self}__error {
      display: block;
    }

    & + #{$self}__label:before {
      content: '';
      border: 1px solid get-color(red, $colors-tertiary);
    }

    & + #{$self}__input:checked + #{$self}__label:after {
      display: none;
    }

    & + #{$self}__label:after {
      background-color: get-color(red, $colors-tertiary);
    }

    & + #{$self}__input:checked ~ #{$self}__label {
      color: get-color(petrol);
    }

    & + #{$self}__input:checked ~ #{$self}__error {
      display: none;
    }
  }

  // disabled state
  &[disabled] {
    color: get-color(grey, $colors-greyScale);

    #{$self}__label:before {
      border: 1px solid get-color(grey, $colors-greyScale);
    }

    &:hover #{$self}__label {
      cursor: not-allowed;
    }
  }
}
