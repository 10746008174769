.m-quote-highlight {
  $self: &;
  position: relative;
  width: 100%;
  background: get-color(petrol-light);
  overflow: hidden;
  margin: get-space(xl) 0;

  @include mq(l) {
    margin: get-space(xxl) 0;
  }

  .l-container {
    @include mq($until: l) {
      margin: 0;
    }
  }

  .l-container__grid {
    justify-content: center;
    align-items: center;
  }

  &__image-wrapper {
    position: relative;
    align-self: flex-end;
    @include use-grid-span(
      'width',
      (
        m: 6 wide,
        l: 6 wide,
        xl: 6 wide
      ),
      (4 narrow)
    );
  }

  &__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    img {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: auto;
    }
  }

  &__text {
    position: relative;
    padding: get-space(xl) get-space(m) get-space(l) get-space(m);
    overflow: hidden;
    z-index: 1;

    @include use-grid-span(
      'width',
      (
        m: 12,
        l: 6,
        xl: 6
      ),
      (4 narrow)
    );

    @include mq(m) {
      padding: get-space(xl) $outerSpacingTablet get-space(l) $outerSpacingTablet;
      background: transparent;
      border-radius: 0;
    }

    @include mq(l) {
      padding: get-space(xl) 0;
    }
  }

  &__text .a-button {
    margin-top: get-space(s);
  }

  &__icon {
    margin-bottom: get-space(s);
    color: get-color(petrol);

    @include mq(l) {
      margin-bottom: get-space(m);
    }
  }

  &__quote {
    color: get-color(blue-dark);
    @include text-type-headline-sub;

    @include mq(l) {
      font-size: 40px;
      line-height: 54px;
    }
  }

  &__tag {
    color: get-color(blue-dark);
    margin-bottom: get-space(s);

    @include mq(l) {
      margin-bottom: get-space(xs);
    }
  }

  &--image-left {
    .l-container__grid {
      @include mq(l) {
        flex-direction: row-reverse;
      }
    }
  }
}
