.l-container {
  $self: &;

  &__wrapper {
    max-width: $gridMaxWith;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .sg-debug-grid & {
      background: susy-svg-grid() no-repeat scroll;

      @each $breakpoint in map-keys($gridConfigs) {
        @include respond-to-susy($breakpoint) {
          background: susy-svg-grid() no-repeat scroll;
        }
      }
    }
  }

  &__grid {
    @include grid-container();
  }

  &--has-background .l-container__wrapper {
    background: get-color(blue-pastel);
  }

  &--has-margin {
    @include outer-spacing();
  }

  &--space-between {
    #{$self}__grid {
      justify-content: space-between;
    }
  }

  &--column .l-container__grid {
    flex-direction: column;
  }
}
