.a-select {
  $self: &;
  position: relative;
  width: 100%;

  &__select {
    @include mq(l) {
      display: none;
    }
  }

  // native select
  &__select,
  &__default-select {
    @include text-type-copy-focused;
    position: relative;
    height: 38px;
    width: 100%;
    letter-spacing: 0.5px;
    color: get-color(blue-dark);
    background: none;
    outline: none;
    appearance: none;
    border: none;
    border-radius: 0px;
    padding-right: get-space(l);
    border-bottom: 1px solid get-color(blue-dark);

    &[disabled] {
      color: get-color(grey, $colors-greyScale);
      border-bottom: 1px solid get-color(grey, $colors-greyScale);
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      color: get-color(petrol);
      cursor: pointer;
    }
  }

  // elements
  &__label {
    @extend %visually-hidden;
    display: block;
    margin-top: get-space(xxs);

    &--is-shown {
      position: relative !important;
      height: auto;
      width: auto;
      clip: none;
    }
  }

  &__placeholder {
    @include text-type-copy-focused;
    position: relative;
    display: block;
    height: 38px;
    width: 100%;
    letter-spacing: 0.5px;
    color: get-color(blue-dark);
    padding-right: get-space(l);
    border-bottom: 1px solid get-color(blue-dark);

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      color: get-color(petrol);
      cursor: pointer;
    }

    &[disabled] {
      color: get-color(grey, $colors-greyScale);
      border-bottom: 1px solid get-color(grey, $colors-greyScale);
    }
  }

  &__icon {
    position: absolute;
    top: get-space(xxs);
    right: get-space(xxs);
    transform: rotate(90deg);
    transition: all 0.3s cubic-bezier(0.48, 0, 0.5, 1);

    &--active {
      transform: rotateX(90deg);
    }
  }

  &__required {
    position: absolute;
    top: 0;
    right: 28px;
    font-family: $font-family-default;
    font-size: 30px;
  }

  // custom desktop select
  &__custom {
    display: none;

    @include mq(l) {
      display: block;
    }
  }

  &__list {
    display: none;
  }

  &__item {
    margin-top: get-space(xs);
    cursor: pointer;

    &:hover,
    &:focus {
      color: get-color(petrol);
      outline: 0;
    }
  }

  // checkbox
  &__checkbox {
    @extend %visually-hidden;
  }

  &__checkbox:focus + .a-select__placeholder {
    border-color: get-color(petrol);
  }

  &__checkbox:checked ~ .a-select__list {
    display: block;
  }

  // error
  &__error {
    @include text-type-copy;
    display: none;
    margin-top: get-space(xxs);
    color: get-color(red, $colors-tertiary);
  }

  &--error,
  select.user-error,
  select.user-error + &__custom {
    color: get-color(red, $colors-tertiary);

    #{$self}__error {
      display: block;
    }

    #{$self}__select,
    #{$self}__placeholder {
      color: get-color(red, $colors-tertiary);
      border-bottom: 1px solid get-color(red, $colors-tertiary);
    }

    &:hover:not([disabled]) #{$self}__select,
    &:hover:not([disabled]) #{$self}__placeholder {
      color: get-color(red, $colors-tertiary);
    }
  }

  &--error ~ &__icon,
  select.user-error ~ &__icon {
    color: get-color(red, $colors-tertiary);
  }

  select.user-error {
    border-bottom: 1px solid get-color(red, $colors-tertiary);
  }
}
